<template>
  <div class="scatter">
    <div class="scatter_1">
      <echarts01 />
      <echarts02 />
    </div>
    <div class="scatter_2">
      <div class="flex_R_1">
        <echarts03 />
        <div class="flex_R_1_2">
          <div class="type" style="padding-left: 10px">
            宝宝晨、午检异常监控分析
          </div>
          <div class="content" style="display: flex; flex-direction: row">
            <div id="info_10"></div>
            <div id="info_11"></div>
          </div>
        </div>
      </div>
      <div class="flex_R_2">
        <div class="flex_R_2_1">
          <i class="topL"></i>
          <i class="topR"></i>
          <i class="bottomL"></i>
          <i class="bottomR"></i>
          <div class="type" style="padding-left: 10px">幼儿喜爱活动分析</div>
          <div class="content">
            <div id="info_12"></div>
          </div>
        </div>
        <div class="flex_R_2_2">
          <div class="type" style="padding-left: 10px">家长关注分析</div>
          <div class="content">
            <div id="info_13"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts01 from "./manage/echarts01.vue";
import echarts02 from "./manage/echarts02.vue";
import echarts03 from "./manage/echarts03.vue";
import echarts04 from "./PreventionControCenter02/echars04.vue";
import echarts05 from "./PreventionControCenter02/echars05.vue";
import echarts06 from "./PreventionControCenter02/echars06.vue";
import echarts07 from "./PreventionControCenter02/echars07.vue";
import list from "./PreventionControCenter02/list.vue";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      top: "",
      scrollIndex: 0,
      p: "",
      lastData: "",
      nowData: "",
      nextData: "",
    };
  },
  mounted() {},
  methods: {
    init() {
      var scatter_1_2 = document.getElementById("scatter_1_2");
      //鼠标移动到右侧时

      //地图
      var arr = ["容县", "兴业县", "北流市", "博白县", "陆川县", "玉州区"];
      var data_info = [
        [
          110.182278,
          22.648315,
          "名称:玉林市实验幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：玉林市玉州区广场北路58号旁",
          "电话：0775-2667999",
          "玉州区",
          "0018011212369",
        ],
        [
          110.177963,
          22.631127,
          "名称:玉林市实验幼儿园(滨江园)",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：金港路16号(滨江公馆内)",
          "电话：(0775)2293456",
          "玉州区",
          "001801121205E",
        ],
        [
          110.171453,
          22.653939,
          "名称:玉林市实验幼儿园名山园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：广西壮族自治区玉林市玉州区名门路",
          "电话：17776703456",
          "玉州区",
          "001801120921B",
        ],
        [
          110.171453,
          22.648315,
          "名称:玉林市贝贝幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：玉林市陆川县温泉镇通政西路金天地",
          "电话：15777515177",
          "玉州区",
          "0016112909578",
        ],
        [
          109.985805,
          22.283972,
          "名称:小牛津幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：广西壮族自治区玉林市博白县新仲公园",
          "电话：17776703456",
          "博白县",
          "001801121058A",
        ],
        [
          109.990381,
          22.269005,
          "名称:南城艺术幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：玉林市博白县南城路a29栋",
          "电话：2956589",
          "博白县",
          "001801121758",
        ],
        [
          110.542808,
          22.852488,
          "名称:玉林市实验幼儿园(滨江园容县启英幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：容州镇城西大道55-1号",
          "电话：18074751155",
          "容县",
        ],
        [
          110.355462,
          22.715365,
          "名称:广西壮族自治区北流市幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：新芝路3号",
          "电话：(0775)6222248",
          "北流市",
        ],
        [
          109.880988,
          22.74761,
          "名称:天使幼儿园(解放路)",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：玉林市兴业县解放路249号",
          "电话：(0775)8815561",
          "兴业县",
        ],
        [
          109.883225,
          22.743673,
          "名称:兴业七团双语艺术幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：南街路115",
          "电话：(0775)6222248",
          "兴业县",
        ],
        [
          109.887037,
          22.746725,
          "名称:梦之苑幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "兴业县",
        ],
        [
          110.018744,
          22.528705,
          "名称:玉林市福绵区樟木镇劲力幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.018744,
          22.528705,
          "名称:玉林市福绵区樟木镇中村幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          109.999845,
          22.579029,
          "名称:玉林市福绵区成均镇第二中心幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.161432,
          22.63958,
          "名称:玉林市环东少年业余学校",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.141279,
          22.64088,
          "名称:玉林市玉豸中心幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.161978,
          22.622682,
          "名称:玉林市岭塘幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.161978,
          22.622682,
          "名称:玉林市南江镇石子岭中英文幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          106.851217,
          32.374979,
          "名称:玉林市南江镇中心幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.161978,
          22.622682,
          "名称:玉林市华勤幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.161978,
          22.622682,
          "名称:玉林市分界圩中英文幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.157431,
          22.634493,
          "名称:玉林市镇邮电所幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.158245,
          22.640298,
          "名称:玉林市玉林荔园新村保育院",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.167298,
          22.640318,
          "名称:玉林市轻轻幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.158245,
          22.640298,
          "名称:玉林市超人幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.155077,
          22.641342,
          "名称:玉林市蓓蕾幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.162508,
          22.596711,
          "名称:玉林市英华中英文幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.158245,
          22.640298,
          "名称:玉林市苗苗幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.173128,
          22.613024,
          "名称:玉林市苗园中英文幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.161978,
          22.622682,
          "名称:玉林市立信中英文幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
        [
          110.018744,
          22.528705,
          "名称:玉林市福绵区樟木镇樟木中心幼儿园",
          "运营情况：正在运营中",
          "安全级别：高等",
          "地址：建设路72号",
          "电话：18934882509",
          "玉州区",
        ],
      ];

      function GetRequest() {
        var url = decodeURI(decodeURI(location.search)); //获取 url 中"?"符后的字串，使用了两次decodeRUI解码
        var strs = new Array();
        strs = url.split("?");
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == strs[1]) {
            arr_name = arr[i];
            lv = "14";
          } else if (strs == "") {
            arr_name = "玉林";
            lv = "13";
          }
        }

        var map = new BMap.Map("scatter_2", {
          enableMapClick: false,
        });
        var cityName = arr_name;
        var point = cityName;
        map.centerAndZoom(point, lv); // 初始化地图,设置中心点坐标和地图级别。    map.addControl(new BMap.ScaleControl());                    // 添加比例尺控件
        map.addControl(new BMap.OverviewMapControl()); //添加缩略地图控件
        map.enableScrollWheelZoom();
        //夜间模式
        // map.setMapStyle({style:'midnight'});
        //地图左侧标尺
        // map.addControl(new BMap.NavigationControl({ type: BMAP_NAVIGATION_CONTROL_LARGE ,anchor: BMAP_ANCHOR_TOP_LEFT, offset: new BMap.Size(40, 250)}));
        var bdary = new BMap.Boundary();
        bdary.get(cityName, function (rs) {
          //获取行政区域
          map.clearOverlays(); //清除地图覆盖物
          //网上查了下，东西经南北纬的范围
          var EN_JW = "180, 90;"; //东北角
          var NW_JW = "-180,  90;"; //西北角
          var WS_JW = "-180, -90;"; //西南角
          var SE_JW = "180, -90;"; //东南角
          //4.添加环形遮罩层
          var ply1 = new BMap.Polygon(
            rs.boundaries[0] + SE_JW + SE_JW + WS_JW + NW_JW + EN_JW + SE_JW,
            {
              strokeColor: "none",
              fillColor: "#04143A",
              fillOpacity: 1,
              strokeOpacity: 0.5,
            }
          ); //建立多边形覆盖物
          map.addOverlay(ply1);

          //5. 给目标行政区划添加边框，其实就是给目标行政区划添加一个没有填充物的遮罩层
          var ply = new BMap.Polygon(rs.boundaries[0], {
            strokeWeight: 2,
            strokeColor: "#6984D5",
            fillColor: "",
          });
          map.addOverlay(ply);
          // map.setViewport(ply.getPath());    //调整视野
          var point = new Array(); //存放标注点经纬信息的数组
          var marker = new Array(); //存放标注点对象的数组
          var info = new Array(); //存放提示信息窗口对象的数组
          var searchInfoWindow = new Array(); //存放检索信息窗口对象的数组
          var opts = {
            width: 250, // 信息窗口宽度
            // 信息窗口高度
            title: "位置信息", // 信息窗口标题
            enableMessage: true, //设置允许信息窗发送短息
          };
          var data_1 = [];
          var data_2 = [];
          var data_3 = [];

          for (var i = 0; i < data_info.length; i++) {
            if (data_info[i][7] == cityName || strs == "") {
              data_1.push(data_info[i][0]);
              data_2.push(data_info[i][1]);
              data_3.push(data_info[i][2]);
              let index = i;
              var myIcon = new BMap.Icon(
                "../imgs/biaozhu.png",
                new BMap.Size(40, 40),
                {
                  anchor: new BMap.Size(20, 35),
                  imageOffset: new BMap.Size(0, 0),
                }
              );
              var marker = new BMap.Marker(
                new BMap.Point(data_info[i][0], data_info[i][1]),
                {
                  icon: myIcon,
                }
              ); // 创建标注
              // marker.setAnimation(BMAP_ANIMATION_BOUNCE);  //标注弹跳
              var content =
                data_info[i][2] +
                "</br>" +
                data_info[i][3] +
                "</br>" +
                data_info[i][4] +
                "</br>" +
                data_info[i][5] +
                "</br>" +
                data_info[i][6];
              map.addOverlay(marker); // 将标注添加到地图中
              addClickHandler(content, marker, index);
            }
          }

          //需要调用的点击事件
          function addClickHandler(content, marker, index) {
            marker.addEventListener("click", function (e) {
              openInfo(content, e);
            });
          }

          function openInfo(content, e) {
            var p = e.target;
            var point = new BMap.Point(
              p.getPosition().lng,
              p.getPosition().lat
            );
            var infoWindow = new BMap.InfoWindow(content, opts); // 创建信息窗口对象
            map.openInfoWindow(infoWindow, point); //开启信息窗口
          }
          //搜索功能
          var data = [];
          var arr = "";
          var val = "";
          //获取幼儿园名字
          for (let i = 0; i < data_3.length; i++) {
            data.push(data_3[i].replace("名称:", ""));
          }
          //初始化搜索栏全部显示
          for (let i = 0; i < data.length; i++) {
            arr +=
              '<div class="lis" title="' +
              data[i] +
              '">' +
              data[i] +
              '</div><span class="option"></span>';
            scatter_1_2.innerHTML = arr;
          }
          //当搜索栏变化成空时
          $("input").bind("input propertychange", function () {
            if (bar.value == "") {
              $("#scatter_1_2").empty();
              arr = "";
              for (let i = 0; i < data.length; i++) {
                arr +=
                  "<div class=" +
                  "lis>" +
                  data[i] +
                  "</div><span class='option'></span>";
                scatter_1_2.innerHTML = arr;
              }
            }
          });
          //点击搜索时
          $("#search").click(function () {
            if (bar.value != "") {
              $("#scatter_1_2").empty();
              arr = "";
              for (let i = 0; i < data.length; i++) {
                val = "";
                val = bar.value;
                if (data[i].indexOf(val) > -1) {
                  arr +=
                    "<div class=" +
                    "lis>" +
                    data[i] +
                    "</div><span class='option'></span>";
                }
              }
              scatter_1_2.innerHTML = arr;
              val = "";
            } else {
              bar.placeholder = "请输入你要搜索的幼儿园";
            }
          });
          //幼儿园点击事件
          $("#scatter_1_2").on("click", "div", function (e) {
            abc = e.target.innerHTML;
            if (e.target.innerHTML != "") {
              scatter_2.style.display = "block";
              scatter_2_2.style.display = "none";
              leave1();
              leave();
              //调用地图标注点击事件
              for (let i = 0; i < data_3.length; i++) {
                if (data_3[i].replace("名称:", "") == abc) {
                  index = i;
                }
              }
              point = new BMap.Point(data_1[index], data_2[index]);
              map.centerAndZoom(point, lv);
              $(".BMap_noprint")[index].click();
            }
            //延时自带双击，避免出现显示一半的BUG
            setTimeout(function () {
              point = new BMap.Point(data_1[index], data_2[index]);
              map.centerAndZoom(point, lv);
              $(".BMap_noprint")[index].click();
            }, 50);
          });
          //摄像头点击
          $("#scatter_1_2").on("click", "span", function (e) {
            // var a_a=document.getElementById("a_a");
            console.log(e);

            var name = e.currentTarget.previousElementSibling.innerHTML;
            scatter_2.style.display = "none";
            scatter_2_2.style.display = "flex";
            var a_a = document.getElementById("a_a");
            var pack = document.getElementById("pack");
            var scatter_2_2_2 = document.getElementById("scatter_2_2_2");
            var h = $("#a_a").height();
            pack.style.height = h + "px";
            //老师信息接口
            var terminal_id = "0017110103187";
            $.ajax({
              url: "https://msg.ibotn.com/yunplat/content/getclassTCinfo.do",
              data: {
                //机器ID
                terminal_id: terminal_id,
              }, //以键/值对的形式
              async: false,
              type: "POST",
              success: function (res) {
                console.log(res);
              },
            });
            var aaaa = [1, 1, 1, 1, 1, 1, 1];
            var bbbb = {
              name: [
                "韦小妮",
                "刘 静",
                "梁星鈺",
                "吴承义",
                "尹新",
                "黄晓君",
                "李桔",
              ],
              age: ["21", "22", "23", "24", "25", "26", "27"],
              tele: [
                "15972229410",
                "15627866254",
                "13682909432",
                "15659831925",
                "18159021692	",
                "15899790107",
                "15914018830",
              ],
              abc: [
                "ture",
                "ture",
                "ture",
                "false",
                "ture",
                "false",
                "ture",
                "false",
              ],
              nub: ["31", "33", "27", "28", "29", "31"],
              bbb: ["小一班", "小二班", "中一班", "中二班", "大一班", "大二班"],
            };
            // 循环生成老师信息
            for (let i = 0; i < aaaa.length; i++) {
              if (bbbb.abc[i] == "ture") {
                $("div#pack").append(
                  "<div><img src='../imgs/tupian" +
                    i +
                    ".png'><span>姓名：" +
                    bbbb.name[i] +
                    "<img class='guohui' src='../imgs/guohui.png'></span></span><span>电话：" +
                    bbbb.tele[i] +
                    "</span></div>"
                );
              } else {
                $("div#pack").append(
                  "<div><img src='../imgs/tupian" +
                    i +
                    ".png'><span>姓名：" +
                    bbbb.name[i] +
                    "</span><span>电话：" +
                    bbbb.tele[i] +
                    "</span></div>"
                );
              }
            }
            //循环下方机器人信息
            for (let i = 0; i < 6; i++) {
              $("div#pack_2").append(
                "<div><span>机器编号:" +
                  data_info[i][8] +
                  "</span><span>所在班级:" +
                  bbbb.bbb[i] +
                  "</span><span>老师人数:3个</span><span>在线状态:在线</span><span>小朋友人数:" +
                  bbbb.nub[i] +
                  "</span></div>"
              );
            }
            //获取机器ID并赋值
            $("#pack_2>div").click(function (e) {
              if (ins1 == "") {
                $("#video").find("div:eq(1)").remove();
                leave1();
                ins2 = "";
                dst.value = e.currentTarget.innerHTML.substr(11, 13);
                account.value =
                  "Y" +
                  e.currentTarget.innerHTML.substr(11, 13).substring(1, 13) +
                  "0";
                channel.value =
                  "Y" + e.currentTarget.innerHTML.substr(11, 13) + "0_Monitor";
                ins1 = e.currentTarget.innerHTML.substr(11, 13);
                if (ins1 != ins2) {
                  $(function () {
                    //机器人信息赋值
                    join();
                  });
                  //清除使用
                } else {
                  ins1 = "";
                }
              } else {
                leave();
                ins1 = "";
                $("#video").find("div:eq(1)").remove();
                //join点击调用
                dst1.value = e.currentTarget.innerHTML.substr(11, 13);
                account1.value =
                  "Y" +
                  e.currentTarget.innerHTML.substr(11, 13).substring(1, 13) +
                  "0";
                channel1.value =
                  "Y" +
                  e.currentTarget.innerHTML.substr(11, 13).substring(1, 13) +
                  "0_Monitor";
                ins2 = e.currentTarget.innerHTML.substr(11, 13);
                if (ins2 != ins1 && ins2 != ins3 && ins2 != ins4) {
                  $(function () {
                    //机器人信息赋值
                    join1();
                  });
                } else {
                  ins2 = "";
                }
              }
              if (
                ins2 == e.currentTarget.innerHTML.substr(11, 13) ||
                ins1 == e.currentTarget.innerHTML.substr(11, 13)
              ) {
                for (let i = 0; i < $("#pack_2>div").length; i++) {
                  $("#pack_2>div")
                    .eq(i)
                    .css("background", "rgba(38, 155, 233, 0.2)");
                }
                this.style = "background:rgba(38, 155, 233, 1)";
                console.log(this);
              }
            });

            $("#pack_2>div").eq(0).click();
            $("#video").append("<div id='span'><span></span></div>");
            setInterval(function () {
              var myDate = new Date();
              $("#span>span").html(myDate.toLocaleString());
            }, 1000);
          });
        });
      }

      if (!AgoraRTC.checkSystemRequirements()) {
        alert("Your browser does not support WebRTC!");
      }

      AgoraRTC.Logger.error("this is error");
      AgoraRTC.Logger.warning("this is warning");
      AgoraRTC.Logger.info("this is info");
      AgoraRTC.Logger.debug("this is debug");

      var audioSelect = document.querySelector("select#audioSource");
      var videoSelect = document.querySelector("select#videoSource");
      var agora = Agora.createNew();
      var agora1 = Agora.createNew();
      var agora2 = Agora.createNew();
      var agora3 = Agora.createNew();

      function join() {
        agora.setParam(
          channel.value,
          account.value,
          dst.value,
          videoSource.value,
          audioSource.value
        );
        agora.Print();
        agora.join();
      }

      function leave() {
        agora.leave();
      }

      function join1() {
        agora1.setParam(
          channel1.value,
          account1.value,
          dst1.value,
          videoSource.value,
          audioSource.value
        );
        agora1.Print();
        agora1.join();
      }

      function leave1() {
        agora1.leave();
      }

      function join2() {
        agora2.setParam(
          channel2.value,
          account2.value,
          dst2.value,
          videoSource.value,
          audioSource.value
        );
        agora2.Print();
        agora2.join();
      }

      function leave2() {
        agora2.leave();
      }

      function join3() {
        agora3.setParam(
          channel3.value,
          account3.value,
          dst3.value,
          videoSource.value,
          audioSource.value
        );
        agora3.Print();
        agora3.join();
      }

      function leave3() {
        agora3.leave();
      }

      function getDevices() {
        AgoraRTC.getDevices(function (devices) {
          for (var i = 0; i !== devices.length; ++i) {
            var device = devices[i];
            var option = document.createElement("option");
            option.value = device.deviceId;
            if (device.kind === "audioinput") {
              option.text =
                device.label || "microphone " + (audioSelect.length + 1);
              audioSelect.appendChild(option);
            } else if (device.kind === "videoinput") {
              option.text =
                device.label || "camera " + (videoSelect.length + 1);
              videoSelect.appendChild(option);
            } else {
              console.log("Some other kind of source/device: ", device);
            }
          }
        });
      }

      //audioSelect.onchange = getDevices;
      //videoSelect.onchange = getDevices;
      getDevices();
      $("#video").on("click", "span", function (e) {
        // leave()

        var id = $(this).parent().attr("id").substring(12);
        if (ins1 == id) {
          ins1 = "";
          if (ins4 == "" && ins3 == "" && ins2 == "" && ins1 == "") {
            scatter_2.style.display = "block";
            video.style.display = "none";
          }
          leave();
          // //删除占用位置的当前父元素
          $(this).parent().remove();
          document.getElementById("join").disabled = false;
        } else if (ins2 == id) {
          ins2 = "";
          if (ins4 == "" && ins3 == "" && ins2 == "" && ins1 == "") {
            scatter_2.style.display = "block";
            video.style.display = "none";
          }
          leave1();
          // //删除占用位置的当前父元素
          $(this).parent().remove();
          document.getElementById("join1").disabled = false;
        } else if (ins3 == id) {
          ins3 = "";
          if (ins4 == "" && ins3 == "" && ins2 == "" && ins1 == "") {
            scatter_2.style.display = "block";
            video.style.display = "none";
          }
          leave2();
          // //删除占用位置的当前父元素
          $(this).parent().remove();
          document.getElementById("join2").disabled = false;
        } else if (ins4 == id) {
          ins4 = "";
          if (ins4 == "" && ins3 == "" && ins2 == "" && ins1 == "") {
            scatter_2.style.display = "block";
            video.style.display = "none";
          }
          leave3();
          // //删除占用位置的当前父元素
          $(this).parent().remove();
          document.getElementById("join3").disabled = false;
        }
      });
      // $(function(){

      // // $('#video').bind('div propertychange', function() {

      // // if (ins1==''&&ins2==''&&ins3==''&&ins4=='') {
      // //     scatter_2.style.display = 'block'
      // //             video.style.display = 'none'
      // // }

      // // });
      // $("#video").onpropertychange(function(){
      //     if (ins1==''&&ins2==''&&ins3==''&&ins4=='') {
      //     scatter_2.style.display = 'block'
      //             video.style.display = 'none'
      // }
      // })

      // })
      function checkLeave() {
        ins1 = "";
        leave();
        ins2 = "";
        leave1();
        ins3 = "";
        leave2();
        ins4 = "";
        leave3();
      }
    },
  },
  components: {
    echarts01,
    echarts02,
    echarts03,
    echarts04,
    echarts05,
    echarts06,
    echarts07,
    list,
  },
};
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "微软雅黑";
  background: #04143a;
}
#navigation {
  height: 78px;
  width: 100%;
}
/* 挂件 */
#skip {
  background: #050e28;
  height: 100%;
  width: 48px;
  position: fixed;
  top: 78px;
  z-index: 100;
  box-shadow: inset 0 0 30px #07417a;
}
#skip img {
  height: 36px;
  width: 36px;
  margin-left: 6px;
  display: block;
  margin-top: 20px;
}
.skip_1 {
  background: #050e28;
  float: left;
  width: 100px;
  height: 80px;
  cursor: pointer;
  line-height: 80px;
  color: #13a3e6;
  display: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-left: 10px;
}
.use {
  height: 80px;
  width: 48px;
}
.use > .tu_1 {
  height: 80px;
  width: 48px;
  float: left;
  cursor: pointer;
}
/* .use:hover .tu_1{background: #040B1F}
    .use:hover .skip_1{background:  #040B1F} */

/* 顶部 */
.title {
  font-size: 36px;
  overflow: hidden;
  padding-left: 80px;
  line-height: 78px;
  height: 78px;
  width: 100%;
  color: #13a3e6;
  box-shadow: inset 0 0 40px #07417a;
  background-repeat: no-repeat;
  background: #040b1f;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}
.title img {
  height: 78px;
  width: 78px;
  float: left;
}
.data {
  position: absolute;
  top: 22px;
  right: 8%;
  width: 120px;
  z-index: 100;
}
.data > span {
  color: #13a3e6;
}
.icon {
  background-image: url(../imgs/icon.png);
  height: 30px;
  width: 30px;
  background-size: 100%;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}

/* 下方总布局 */
.scatter {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  padding-left: 48px;
  padding-top: 80px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  box-sizing: border-box;
  background: #04143a;
}
.scatter_1,
.scatter_2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 5px 0 2px 0;
  padding: 0;
}
.content {
  height: 89%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* 左侧 */
/* 幼儿园信息报表 */
.flex_L_1 {
  border: 2px solid #042b66;
  border-radius: 5px;
  flex: 2;
  background: #04143a;
  margin: 0px 5px 5px 10px;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}
.top {
  flex: 1;
  display: flex;
}
.bottom {
  flex: 1;
  display: flex;
  background-color: #04143a;
}
#info_1,
#info_2,
#info_3,
#info_4,
#info_5 {
  flex: 1;
}
/* 左下 */
/* 幼儿园出勤 */
.flex_L_2 {
  border: 2px solid #042b66;
  border-radius: 5px;
  flex: 1;
  background: #04143a;
  margin: 5px 5px 5px 10px;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}
#info_6 {
  height: 100%;
  width: 100%;
}

/* 右侧上方 */
/* 教师信息报表 */
.flex_R_1 {
  border: 2px solid #042b66;
  border-radius: 5px;
  flex: 2;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 0 30px #07417a;
  background: #04143a;
  box-sizing: border-box;
  margin: 0px 10px 5px 5px;
  position: relative;
}
.flex_R_1_1 {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#info_7,
#info_8,
#info_9 {
  flex: 1;
}

/* 幼儿健康分析 */
.flex_R_1_2 {
  flex: 1;
}
#info_10 {
  flex: 1;
}
/* 右侧下方 */
/* 幼儿喜爱活动分析 */
.flex_R_2 {
  border: 2px solid #042b66;
  border-radius: 5px;
  flex: 1;
  background: #04143a;
  display: flex;
  margin: 5px 10px 5px 5px;
  position: relative;
  box-shadow: inset 0 0 30px #07417a;
}
.flex_R_2_1,
.flex_R_2_2 {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.flex_R_2 content {
  flex: 1;
  display: flex;
}
#info_11,
#info_12,
#info_13 {
  flex: 1;
}
/* 表格名字格式 */
.type {
  height: 30px;
  line-height: 30px;
  color: #1296db;
  font-size: 18px;
  background: #04143a;
  border-bottom: 2px solid #042b66;
  box-shadow: inset 0 0 30px #07417a;
}

.topR {
  width: 20px;
  height: 20px;
  border-top-width: 3px;
  border-top-color: #26c6f0;
  border-top-style: solid;
  border-right-width: 3px;
  border-right-color: #26c6f0;
  border-right-style: solid;
  position: absolute;
  top: -3px;
  right: -3px;
  border-top-right-radius: 5px;
}
.topL {
  width: 20px;
  height: 20px;
  border-top-width: 3px;
  border-top-color: #26c6f0;
  border-top-style: solid;
  border-left-width: 3px;
  border-left-color: #26c6f0;
  border-left-style: solid;
  position: absolute;
  top: -3px;
  left: -3px;
  border-top-left-radius: 5px;
}
.bottomL {
  width: 20px;
  height: 20px;
  border-bottom-width: 3px;
  border-bottom-color: #26c6f0;
  border-bottom-style: solid;
  border-left-width: 3px;
  border-left-color: #26c6f0;
  border-left-style: solid;
  position: absolute;
  bottom: -3px;
  left: -3px;
  border-bottom-left-radius: 5px;
}
.bottomR {
  width: 20px;
  height: 20px;
  border-bottom-width: 3px;
  border-bottom-color: #26c6f0;
  border-bottom-style: solid;
  border-right-width: 3px;
  border-right-color: #26c6f0;
  border-right-style: solid;
  position: absolute;
  bottom: -3px;
  right: -3px;
  border-bottom-right-radius: 5px;
}
</style>
