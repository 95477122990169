<template>
  <div id="navigation">
    <div class="title">
      <img src="../imgs/timg.png" alt="" /> i智教云管理平台
    </div>
    <div class="data">
      <div class="icon"></div>
      <span class="name"></span><span class="level">管理员</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      sidebarList: [
        { imgsrc: "./src/imgs/page.png" },
        { imgsrc: "../imgs/serve_1.png" },
        { imgsrc: "../imgs/school.png" },
      ],
      headerTitle: "",
    };
  },
  created() {
    // let path = this.$route.path;
    // console.log(path);
    // this.setHeaderTitle(path)
  },
  methods: {
    // getPath(newv,oldv){
    //   console.log(newv,oldv)
    //   this.setHeaderTitle(newv.path)
    // },
    // setHeaderTitle(path){
    //   console.log(path)
    //   if(path === '/'){
    //     this.headerTitle = '机器使用分析'
    //   }else if(path === '/PreventionControCenter02'){
    //     this.headerTitle = '疫情防控监测'
    //   }else if(path === 'intelligentBobot03'){
    //     this.headerTitle = '平台使用分析'
    //   }
    // }
  },
  watch: {
    $route: "getPath",
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
