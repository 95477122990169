<template>
  <div class="echarts-box" id="stackLineMore1"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      sidebarList: [
        { imgsrc: "./src/imgs/page.png" },
        { imgsrc: "../imgs/serve_1.png" },
        { imgsrc: "../imgs/school.png" },
      ],
    };
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
        document.getElementById("stackLineMore1")
      );
      let option_init_1 = {
        tooltip: {
          // trigger: 'item'
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            // name: 'Access From',
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 37341, name: "1日" },
              { value: 27890, name: "2日" },
              { value: 48234, name: "3日" },
              { value: 50098, name: "一周" },
              { value: 2003, name: "其他" },
            ],
          },
        ],
      };
      myChart_init_1.setOption(option_init_1);
    },
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.echarts-box {
  width: 100%;
  height: 200px;
}
</style>
