<template>
  <div class="box">
    <img src="../assets/fire.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "teacher",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  components: {},
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  box-sizing: border-box;
}
.box img {
  width: 100%;
  height: 100%;
}
</style>
