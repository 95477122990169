<template>
  <div
    class="echarts-box"
    id="stackLineMore5"
    style="display: inline-block; width: 100%"
  ></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
        document.getElementById("stackLineMore5")
      );
      let option_init_1 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["开园", "未开园"],
          textStyle: {
            color: "rgb(255, 255, 255) ",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "10%",
          containLabel: true,
        },
        color: ["#85C4E7", "#6984D5", "#8782D2", "#A498D6"],
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: ["玉州区", "北流市", "博白县", "陆川县", "兴业县"],
          axisLine: {
            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
            boundaryGap: false,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,

            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "开园",
            type: "bar",
            stack: "总量",
            label: {
              normal: {
                show: true,
              },
            },
            data: [221, 145, 167, 201, 112],
          },
          {
            name: "未开园",
            type: "bar",
            stack: "总量",
            label: {
              normal: {
                show: true,
              },
            },
            data: [23, 11, 8, 9, 6],
          },
        ],
      };
      myChart_init_1.setOption(option_init_1);
    },
  },
};
</script>

<style scoped>
.echarts-box {
  width: 100%;
  height: 200px;
}
</style>
