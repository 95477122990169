<template>
  <div class="echarts-box" id="stackLineMore6" style="display:inline-block;width:100%;height:100%;"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      data1: [6895, 6893, 6892, 6891, 6890, 6887, 6887],
      data2: [12, 11, 12, 12, 13, 10, 10],
      data3: [28, 26, 25, 24, 23, 20, 20],
      data4: [43, 41, 40, 39, 38, 35, 35],
      data5: [73, 71, 70, 69, 68, 65, 65],
      data6: [128, 126, 125, 124, 124, 121, 121],
    };
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
        document.getElementById("stackLineMore6")
      );
      let option_init_1 = {
        tooltip: {
                trigger: 'axis',
                axisPointer: { // 坐标轴指示器，坐标轴触发有效
                    type: '' // 默认为直线，可选为：'line' | 'shadow'
                }
            },
            legend: {
                data: ['开园', '未开园'],
                textStyle: {
                    color: "rgb(255, 255, 255) "
                },
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: '10%',
                containLabel: true
            },
            color: ['#85C4E7', '#6984D5', '#8782D2', '#A498D6'],
            xAxis: {
                type: 'category',
                data: [
                    '第1单元',
                    '第2单元',
                    '第3单元',
                    '第4单元',
                    '第5单元',
                    '第6单元',
                    '第7单元',
                    '第8单元',
                    '第9单元',
                    '第10单元',
                    '第11单元',
                    '第12单元',
                    '第13单元',
                    '第14单元',
                    '第15单元',
                    '第16单元',
                    '第17单元',
                    '第18单元'
                ],
                axisLine: {
                    lineStyle: {
                        color: "rgb(255, 255, 255) "
                    },
                    boundaryGap: false
                },
                axisTick:{
                    show:false,
                },
            },
            yAxis: {
                type: 'value',
                axisLine: {
                   show:true,
                    lineStyle: {
                        color: "rgb(255, 255, 255) "
                    }
                },

                splitLine: {
                    show: false
                }
            },
            series: [
               {
            name: "小班",
            type: "bar",
            stack: "总量",
            smooth: true,
            data: [534,567,501,543,601,587,589,540,501,487,589,543,501,600,587,601,498,456],
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
          {
            name: "中班",
            type: "bar",
            stack: "总量",
            smooth: true,
            data: [432,421,488,465,500,476,510,501,540,589,512,587,523,578,520,500,501,487],
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
          {
            name: "大班",
            type: "bar",
            stack: "总量",
            smooth: true,
            data: [678,588,586,543,620,620,559,530,501,467,555,543,630,601,534,501,498,467],
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          }
            ]
      };
      myChart_init_1.setOption(option_init_1);

    },
  },

};
</script>

<style scoped>
    .echarts-box{
        width:100%;
        height: 100%;
    }
</style>
