<template>
  <div class="BOX">
    <div class="zonghee">
      <i class="topL"></i>
      <i class="topR"></i>
      <i class="bottomL"></i>
      <i class="bottomR"></i>

      <div class="Faces">
        <samp>幼儿园总量</samp>
        <p class="cai">870</p>
      </div>
      <!-- <div class="Faces">
        <samp>周新增</samp>
        <p class="cai">34</p>
      </div> -->
      <div class="Faces">
        <samp>日活跃</samp>
        <p class="cai">357</p>
      </div>
      <div class="Faces">
        <samp>周活跃</samp>
        <p class="cai">512</p>
      </div>
      <div class="Faces">
        <samp>当前在线</samp>

        <p class="cai">189</p>
      </div>
      <div class="Faces">
        <samp>当日访问量</samp>
        <p class="cai">23579</p>
      </div>
    </div>
    <!-- 下身 -->
    <div class="LowerBody hp9">
      <!-- 左 -->
      <div class="LeFt">
        <div class="flex hp3 pb10 bsizing">
          <div class="laosi flex flex-column shishidongtai">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div style="padding-left: 10px" class="shishi main-title">
              活跃用户分析
            </div>
            <Analysis class="flex1" />
          </div>
        </div>
        <div class="flex hp3 pb10 bsizing">
          <div class="shishi9 flex flex-column shishidongtai">
            <div style="padding-left: 10px" class="shishi main-title">
              各功能使用分析
            </div>
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <Analygig class="flex1" />
          </div>
        </div>
        <div class="flex hp3 pb10 bsizing">
          <div
            class="shishi9 shishidongtai"
            style="height: 100%; max-height: 100%"
          >
            <div style="padding-left: 10px" class="shishi main-title">
              订单处理动态
            </div>
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <Analycss class="scrol-box" />
          </div>
        </div>
      </div>
      <!-- 中 -->
      <div class="LeFt1">
        <div class="flex hp6 pb10 bsizing">
          <div class="middlebox flex flex-column mt5">
            <div style="padding-left: 10px" class="shishi main-title">
              散点图
            </div>
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div class="flex1">
              <Analypui style="height: 100%" />
            </div>
          </div>
        </div>
        <div class="flex hp3 pb10 bsizing">
          <div class="buxiang flex">
            <div class="flex flex-column shishidongtai2">
              <div style="padding-left: 10px" class="shishi main-title">
                每日订单
              </div>
              <i class="topL"></i>
              <i class="topR"></i>
              <i class="bottomL"></i>
              <i class="bottomR"></i>
              <Analypai />
            </div>
            <div class="flex flex-column shishidongtai2">
              <div style="padding-left: 10px" class="shishi main-title">
                完成率分析
              </div>
              <i class="topL"></i>
              <i class="topR"></i>
              <i class="bottomL"></i>
              <i class="bottomR"></i>
              <Analyxix />
            </div>
          </div>
        </div>
      </div>
      <!-- 右 -->
      <div class="LeFt2">
        <div class="flex hp3 pb10 bsizing">
          <div class="shishidongtai3 flex1 flex flex-column mt5">
            <div style="padding-left: 10px" class="shishi main-title">
              安防检查分析
            </div>
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <Analypip class="flex1" />
          </div>
        </div>
        <div class="flex hp3 pb10 bsizing">
          <div class="shishi2 flex1 flex flex-column shishidongtai">
            <div style="padding-left: 10px" class="shishi main-title">
              每日新增文档分析
            </div>
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <Analyshida class="flex1" />
          </div>
        </div>
        <div class="flex hp3 pb10 bsizing">
          <div class="shishi9 flex flex-column shishidongtai">
            <div style="padding-left: 10px" class="shishi main-title">
              平台使用动态：
            </div>
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <analycss-two />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Analysis from "@/components/intelligentBobot03/Analysis.vue";
import Analyxix from "@/components/intelligentBobot03/Analysis01.vue";
import Analygig from "@/components/intelligentBobot03/Analysis02.vue";
import Analypip from "@/components/intelligentBobot03/Analysis03.vue";
import Analypui from "@/components/intelligentBobot03/Analysis04.vue";
//  import Analypuu from '@/components/intelligentBobot03/Analysis05.vue'
import Analypai from "@/components/intelligentBobot03/Analysis06.vue";
import Analycss from "@/components/intelligentBobot03/Analysis07.vue";
import AnalycssTwo from "@/components/intelligentBobot03/Analysis10.vue";
//  import Analykao from '@/components/intelligentBobot03/Analysis08.vue'
//  import Analykkk from '@/components/PlatformUsageCenter01/echart07.vue'
import Analyshida from "@/components/intelligentBobot03/Analysis09.vue";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  components: {
    Analysis,
    Analyxix,
    Analygig,
    Analypip,
    Analypui,
    // Analypuu,
    Analypai,
    Analycss,
    // Analykao,
    // Analykkk
    Analyshida,
    AnalycssTwo,
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.BOX {
  width: 100%;
  height: 100%;
}

.zonghee {
  height: 10%;
  width: 97.5%;
  border: 2px solid #042b66;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Faces {
  color: #13a3e6;
  font-size: 22px;
  border: 2px solid #042b66;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
  margin-top: 8px;
  display: flow;

  flex-wrap: wrap;
}

.cai {
  display: flex;
  justify-content: center;
}

/* 下身大 */
.LowerBody {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
}

/* 左 */
.LeFt {
  width: 31%;
  height: 100%;
}

.LeFtxiao {
  width: 100%;
  height: 30%;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
}

.shishi {
  color: #1388af;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

/* 中上图 */
.middlebox {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}

/* 中 */
.LeFt1 {
  width: 33%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shishi2 {
  width: 100%;
  border: 2px solid #042b66;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
}
.shishi9 {
  width: 100%;
  border: 2px solid #042b66;
  margin-top: 0;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
}

.shishidongtai2 {
  box-sizing: border-box;
  width: 49%;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}

/* 中下图 */
.buxiang {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

/* 右 */
.LeFt2 {
  width: 31%;
  /* height: 99%; */
  display: flex;
  flex-direction: column;
}

.main-title {
  color: #042b66;
  border-bottom: 2px solid #042b66;
  height: 30px;
  line-height: 30px;
}

.shishidongtai {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
  padding-left: 10px;
  margin-top: 5px;
}
.shishidongtaixx {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}

.shishidongtai3 {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}

.scrol-box {
  height: calc(100% - 40px);
  overflow: hidden;
  box-sizing: border-box;
}
</style>
