<template>
  <div id="parentTwo" class="parent 555555">
    <ul id="childTwo" class="child">
      <li v-for="(item, index) in list1" :key="index">
        <span>{{ item.name }}</span
        ><span>{{ item.time }}</span>
      </li>
      
    </ul>
    <div id="cloneChildTwo" class="child"></div>
  </div>
</template>
<script>
import { formatData } from "../../utils/index.js";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      list1:[],
      list: [
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "兴业七团双语艺术幼儿园完成了小班陪餐" },
        { name: "玉林市贝贝幼儿园完成了灭火器检查" },
        { name: "玉林市岭塘幼儿园完成了操场巡查" },
        { name: "玉林市玉豸中心幼儿园进入3名外访人员" },
        { name: "玉林市实验幼儿园提交了一个文档审核" },
        { name: "玉林市名山园完成了安全类文档归档" },
        { name: "玉林市蓓蕾幼儿园提交一个电脑维修单" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "兴业七团双语艺术幼儿园完成了小班陪餐" },
        { name: "玉林市贝贝幼儿园完成了灭火器检查" },
        { name: "玉林市岭塘幼儿园完成了操场巡查" },
        { name: "玉林市玉豸中心幼儿园进入3名外访人员" },
        { name: "玉林市实验幼儿园提交了一个文档审核" },
        { name: "玉林市名山园完成了安全类文档归档" },
        { name: "玉林市蓓蕾幼儿园提交一个电脑维修单" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "兴业七团双语艺术幼儿园完成了小班陪餐" },
        { name: "玉林市贝贝幼儿园完成了灭火器检查" },
        { name: "玉林市岭塘幼儿园完成了操场巡查" },
        { name: "玉林市玉豸中心幼儿园进入3名外访人员" },
        { name: "玉林市实验幼儿园提交了一个文档审核" },
        { name: "玉林市名山园完成了安全类文档归档" },
        { name: "玉林市蓓蕾幼儿园提交一个电脑维修单" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "兴业七团双语艺术幼儿园完成了小班陪餐" },
        { name: "玉林市贝贝幼儿园完成了灭火器检查" },
        { name: "玉林市岭塘幼儿园完成了操场巡查" },
        { name: "玉林市玉豸中心幼儿园进入3名外访人员" },
        { name: "玉林市实验幼儿园提交了一个文档审核" },
        { name: "玉林市名山园完成了安全类文档归档" },
        { name: "玉林市蓓蕾幼儿园提交一个电脑维修单" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "兴业七团双语艺术幼儿园完成了小班陪餐" },
        { name: "玉林市贝贝幼儿园完成了灭火器检查" },
        { name: "玉林市岭塘幼儿园完成了操场巡查" },
        { name: "玉林市玉豸中心幼儿园进入3名外访人员" },
        { name: "玉林市实验幼儿园提交了一个文档审核" },
        { name: "玉林市名山园完成了安全类文档归档" },
        { name: "玉林市蓓蕾幼儿园提交一个电脑维修单" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
        { name: "兴业七团双语艺术幼儿园完成了小班陪餐" },
        { name: "玉林市贝贝幼儿园完成了灭火器检查" },
        { name: "玉林市岭塘幼儿园完成了操场巡查" },
        { name: "玉林市玉豸中心幼儿园进入3名外访人员" },
        { name: "玉林市实验幼儿园提交了一个文档审核" },
        { name: "玉林市名山园完成了安全类文档归档" },
        { name: "玉林市蓓蕾幼儿园提交一个电脑维修单" },
        { name: "小牛津幼儿园园长完成了午餐试吃" },
      
      ],
      sidebarList: [
        { imgsrc: "./src/imgs/page.png" },
        { imgsrc: "../imgs/serve_1.png" },
        { imgsrc: "../imgs/school.png" },
      ],
      myScroll: null,
    };
  },
  created() {},
  mounted() {
    this.list.forEach((val) => {
      val.time = formatData()
    });
    this.list1 = this.list
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      let parent = document.getElementById("parentTwo");
      let child = document.getElementById("childTwo");
      // let str = "";
      // for (let i = 0; i < 10; i++) {
      //   str += `<tr>`;
      //   for (let j = 0; j < 6; j++) {
      //     str += `<li>第${i}行第${j}列</li>`;
      //   }
      //   str += `</tr>`;
      // }

      // child.innerHTML = str;
      let cloneChild = document.getElementById("cloneChildTwo");
      // 深度克隆一份表格 相比 innerHTML 的优势在于可以克隆元素的全部的属性
      let cloneNoe = child.cloneNode(true);
      // 追加到 parent 里面 做无缝切换视觉效果
      parent.appendChild(cloneNoe);

      this.myScroll = setInterval(function () {
        // parent.scrollTop + parent.clientHeight = child.scrollHeight;
        // child.scrollHeight - parent.scrollTop = parent.clientHeight;
        // 让他多滚动 parent 一显示区域的高度。再跳到 最顶部 ，正好 给人一种在不断滚动的错觉
        if (parent.scrollTop >= child.scrollHeight) {
          parent.scrollTop = 0;
        } else {
          parent.scrollTop++;
        }
      }, 20);

      //let _this = this
      //滚动信息
      // var area = this.$refs.bodybox
      // area.scrollTop = 0;
      // var time = 50;

      // //克隆元素
      // var con1 = this.$refs.con1 //document.getElementById("con1");
      // var con2 = this.$refs.con2 //document.getElementById("con2");
      // con2.innerHTML = con1.innerHTML;

      // //初始状态
      // this.myScroll = setInterval(function(){
      //     if (area.scrollTop && area.scrollTop >= con1.scrollHeight) {
      //         area.scrollTop = 0;
      //     } else {
      //         area.scrollTop++;
      //     }
      // }, time);
      // //以下为鼠标悬停的事件
      parent.onmouseover = () => {
        clearInterval(this.myScroll);
      };
      parent.onmouseout = () => {
        this.myScroll = setInterval(function () {
          if (parent.scrollTop >= child.scrollHeight) {
            parent.scrollTop = 0;
          } else {
            parent.scrollTop++;
          }
        }, 20);
      };
    },
    scrollUp() {},
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.parent {
  height: calc(100% - 40px);
  overflow: hidden;
  background-color: transparent;
}
ul li {
  width: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
