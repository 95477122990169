<template>
  <div class="hello">
    <div class="scatter">
      <div class="scatter_1">
        <div class="flex hp3 pb10 bsizing">
          <div class="flex flex-column kaoqin flex1">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div class="title1 main-title" id="main" style="padding-left: 10px">
              新注册用户分析
            </div>
            <echarts01 class="flex1" />
          </div>
        </div>
        <div class="flex hp3 pb10 bsizing">
          <div class="flex flex-column shishidongtai flex1">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div class="title1 main-title" style="padding-left: 10px">
              活跃用户分析
            </div>
            <echarts02 class="flex1" />
          </div>
        </div>
        <div class="flex hp3 bsizing">
          <div class="flex flex-column shishidongtai">
            <div class="title1 main-title" style="padding-left: 10px">
              功能使用分析
            </div>
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <echarts-03 />
          </div>
        </div>
      </div>
      <div class="flex flex-column flex3 scatter-middle">
        <div class="flex hp3 pb10 bsizing">
          <div
            class="container-column-two flex1 flex flex-column"
            style="position: relative"
          >
            <div class="title1 main-title" style="padding-left: 10px">
              实时在线趋势图
            </div>
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <echarts04 class="flex1" />
          </div>
        </div>
        <div class="flex hp6 bsizing">
          <div class="flex flex2 scatter_2">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div class="container-column-three flex flex-column">
              <div class="box flex">
                <div id="box_1">
                  注册
                  <div>8512</div>
                </div>
                <div id="box_1">
                  在线
                  <div>6524</div>
                </div>
                <div id="box_3">
                  记录
                  <div>8542</div>
                </div>
              </div>
            </div>
            <echarts-map />
          </div>
        </div>
      </div>
      <div class="scatter_1">
        <div class="flex hp3 pb10 bsizing">
          <div class="flex1 zonghe flex flex-column">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div class="title1 main-title" style="padding-left: 10px">
              课件装载分析
            </div>
            <echarts05 class="flex1" />
          </div>
        </div>
        <div class="flex hp3 pb10 bsizing">
          <div class="flex1 mb10 zonghe flex flex-column">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div class="main-title title1" style="padding-left: 10px">
              课件使用分析
            </div>
            <echarts06 class="flex1" />
          </div>
        </div>
        <div class="flex hp3 bsizing">
          <div class="zonghe flex flex-column">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div class="main-title title1" style="padding-left: 10px">
              课件使用动态
            </div>
            <echarts07 />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import echarts01 from "./PlatformUsageCenter01/echart01.vue";
import echarts02 from "./PlatformUsageCenter01/echart02.vue";
import echarts03 from "./PlatformUsageCenter01/echart03.vue";
import echarts04 from "./PlatformUsageCenter01/echart04.vue";
import echarts05 from "./PlatformUsageCenter01/echart05.vue";
import echarts06 from "./PlatformUsageCenter01/echart06.vue";
import echarts07 from "./PlatformUsageCenter01/echart07.vue";
import echartsMap from "./PlatformUsageCenter01/echartsMap.vue";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    echarts01,
    echarts02,
    echarts03,
    echarts04,
    echarts05,
    echarts06,
    echarts07,
    echartsMap,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.posi-rela {
  position: relative;
}
.container-column-two {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}
.container-column-three {
  position: relative;
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.zonghe {
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}
.pb10 {
  padding-bottom: 10px;
}
.pb5 {
  padding-bottom: 5px;
}
.scrol-box {
  height: 300px;
  overflow: hidden;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.title1 {
  color: #fff;
}
.box {
  padding-top: 5px;
}
.shishidongtai {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}
.kaoqin {
  border: 2px solid #042b66;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}
.scatter_2 {
  display: flex;
  flex-direction: column;
  background-image: url(../imgs/bottom.png);
  box-shadow: inset 0 0 30px #07417a;
  background-size: 100% 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
}
.scatter-middle {
  padding: 5px 10px 10px 10px;
}
</style>
