<template>
  <div class="flex_R_1_1">
    <i class="topL"></i>
    <i class="topR"></i>
    <i class="bottomL"></i>
    <i class="bottomR"></i>
    <div class="type" style="padding-left: 10px">教师信息报表</div>
    <div class="content" style="display: flex; flex-direction: row">
      <div id="info_7"></div>
      <div id="info_8"></div>
      <div id="info_9"></div>
    </div>
  </div>
</template>
<script>
import echarts07 from "./components/child-echarts01.vue";
import echarts08 from "./components/child-echarts02.vue";
import echarts09 from "./components/child-echarts03.vue";
export default {
  components: {
    echarts07,
    echarts08,
    echarts09,
  },
};
</script>
<style scoped>
.content {
  height: 89%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>