<template>
  <div
    class="echarts-box"
    id="stackLineMore2"
    style="display: inline-block; width: 100%; height: 100%"
  ></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      sidebarList: [
        { imgsrc: "./src/imgs/page.png" },
        { imgsrc: "../imgs/serve_1.png" },
        { imgsrc: "../imgs/school.png" },
      ],
    };
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
        document.getElementById("stackLineMore2")
      );
      let option_init_1 = {
        // legend: {
        //   top: 'bottom'
        // },
        toolbox: {
          show: true,
          feature: {
            // mark: { show: true },
            // dataView: { show: true, readOnly: false },
            // restore: { show: true },
            // saveAsImage: { show: true }
          },
        },
        series: [
          {
            name: "Nightingale Chart",
            type: "pie",
            // radius: [50, 80],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: [
              { value: 40, name: "园务管理" },
              { value: 38, name: "考勤" },
              { value: 32, name: "安全" },
              { value: 30, name: "检修" },
              { value: 28, name: "智能厨房" },
              { value: 26, name: "物质" },
              { value: 22, name: "档案管理" },
              { value: 18, name: "教学活动" },
            ],
          },
        ],
      };
      myChart_init_1.setOption(option_init_1);
    },
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.echarts-box {
  border-radius: 6px;
  box-sizing: border-box;
}
</style>
