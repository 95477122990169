export function formatData() {
    var date = new Date();
    //date.setTime(value);
    var month = date.getMonth() + 1;
    var day = date.getDate()
    if (month < 10)
        month = "0" + month;
    if (day < 10)
        day = "0" + day;
    var time = date.getFullYear() + "-" + month + "-" + day

    return time;
}