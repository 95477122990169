<template>
  <div class="echarts-box" id="stackLineMore9"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
        document.getElementById("stackLineMore9")
      );
      let option_init_1 = {
        // title: {
        //         text: '当日异常小朋友',
        //         //标题颜色
        //         textStyle: {
        //             fontWeight: 'normal',
        //             color: "rgb(255, 255, 255) "
        //         },
        //     },
        tooltip: {},
        legend: {
          data: ["7"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "13%",
          containLabel: true,
        },
        //设置X轴
        xAxis: {
          axisTick: {
            show: false,
          },
          data: [
            "星期一",
            "星期二",
            "星期三",
            " 星期四",
            "星期五",
            "星期六",
            "星期天",
          ],
          axisLine: {
            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
            boundaryGap: false,
          },
        },
        //设置Y轴
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            itemStyle: {
              normal: {
                //这里用函数控制柱子颜色，定义一个list，然后根据所以取得不同的值
                color: function (params) {
                  // 每根柱子的颜色
                  var colorList = [
                    "#133A89",
                    "#174986",
                    "#225FB0",
                    "#366A9A",
                    "#264861",
                    "#366A9A",
                    "#366A9A",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
            //设置柱的宽度，要是数据太少，柱子太宽不美观~
            barWidth: 40,
            data: [3210, 2563, 2894, 3012, 3876, 351, 213],
          },
        ],
        label: {
          show: true,
        },
      };
      myChart_init_1.setOption(option_init_1);
    },
  },
};
</script>

<style scoped>
.echarts-box {
  width: 100%;
  height: 200px;
}
</style>
