<template>
  <div class="flex_L_2">
    <i class="topL"></i>
    <i class="topR"></i>
    <i class="bottomL"></i>
    <i class="bottomR"></i>
    <div class="type main-title" style="padding-left: 10px">幼儿出勤趋势图</div>
    <div class="content">
      <echarts06 />
    </div>
  </div>
</template>
<script>
import echarts06 from "./components/child-echarts06.vue";
export default {
  components: {
    echarts06,
  },
};
</script>
<style scoped>
.content {
  height: 89%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>