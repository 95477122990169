<template>
  <div class="zhaoyang" ref="zhaoyang"></div>
</template>
<script>
import * as echarts from "echarts";
import yulin from "../PlatformUsageCenter01/yulin.json";
import zhaoyang from "../PlatformUsageCenter01/zhaoyang.json";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },

  data() {
    return {
      data_info: [
        {
          lan: "110.182278,22.648315",
          name: "玉林市实验幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "玉林市玉州区广场北路58号旁",
          tel: "125",
          area: "玉州区",
        },
        {
          lan: "110.177963,22.631127",
          name: "玉林市实验幼儿园(滨江园)",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "金港路16号(滨江公馆内)",
          tel: "98",
          area: "玉州区",
        },
        {
          lan: "110.171453,22.653939",
          name: "玉林市实验幼儿园名山园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "广西壮族自治区玉林市玉州区名门路",
          tel: "76",
          area: "玉州区",
        },
        {
          lan: "110.171453,22.648315",
          name: "玉林市贝贝幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "玉林市陆川县温泉镇通政西路金天地",
          tel: "101",
          area: "玉州区",
        },
        {
          lan: "109.985805,22.283972",
          name: "小牛津幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "广西壮族自治区玉林市博白县新仲公园",
          tel: "87",
          area: "博白县",
        },
        {
          lan: "109.990381,22.269005",
          name: "南城艺术幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "玉林市博白县南城路a29栋",
          tel: "102",
          area: "博白县",
        },
        {
          lan: "110.542808,22.852488",
          name: "玉林市实验幼儿园(滨江园容县启英幼儿园）",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "容州镇城西大道55-1号",
          tel: "98",
          area: "容县",
        },
        {
          lan: "110.355462,22.715365",
          name: "广西壮族自治区北流市幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "新芝路3号",
          tel: "88",
          area: "北流市",
        },
        {
          lan: "109.880988,22.74761",
          name: "天使幼儿园(解放路)",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "玉林市兴业县解放路249号",
          tel: "91",
          area: "兴业县",
        },
        {
          lan: "109.883225,22.743673",
          name: "兴业七团双语艺术幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "南街路115",
          tel: "86",
          area: "兴业县",
        },
        {
          lan: "109.887037,22.746725",
          name: "梦之苑幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "92",
          area: "兴业县",
        },
        {
          lan: "110.018744,22.528705",
          name: "玉林市福绵区樟木镇劲力幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "76",
          area: "玉州区",
        },
        {
          lan: "110.018744,22.528705",
          name: "玉林市福绵区樟木镇中村幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "86",
          area: "玉州区",
        },
        {
          lan: "109.999845,22.579029",
          name: "玉林市福绵区成均镇第二中心幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "76",
          area: "玉州区",
        },
        {
          lan: "110.161432,22.63958",
          name: "玉林市环东少年业余学校",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "85",
          area: "玉州区",
        },
        {
          lan: "110.141279,22.64088",
          name: "玉林市玉豸中心幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "89",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市岭塘幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "96",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市南江镇石子岭中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "78",
          area: "玉州区",
        },
        {
          lan: "106.851217,32.374979",
          name: "玉林市南江镇中心幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "96",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市华勤幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "78",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市分界圩中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "95",
          area: "玉州区",
        },
        {
          lan: "110.157431,22.634493",
          name: "玉林市镇邮电所幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "89",
          area: "玉州区",
        },
        {
          lan: "110.158245,22.640298",
          name: "玉林市玉林荔园新村保育院",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "92",
          area: "玉州区",
        },
        {
          lan: "110.167298,22.640318",
          name: "玉林市轻轻幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "96",
          area: "玉州区",
        },
        {
          lan: "110.158245,22.640298",
          name: "玉林市超人幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "86",
          area: "玉州区",
        },
        {
          lan: "110.155077,22.641342",
          name: "玉林市蓓蕾幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "98",
          area: "玉州区",
        },
        {
          lan: "110.158245,22.640298",
          name: "玉林市英华中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "99",
          area: "玉州区",
        },
        {
          lan: "110.173128,22.613024",
          name: "玉林市苗苗幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "93",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市苗园中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "96",
          area: "玉州区",
        },
        {
          lan: "110.018744,22.528705",
          name: "玉林市立信中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "98",
          area: "玉州区",
        },
      ],
      data_info1: [
        {
          lan: "110.182278,22.648315",
          name: "玉林市实验幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "玉林市玉州区广场北路58号旁",
          tel: "98",
          area: "玉州区",
        },
        {
          lan: "110.177963,22.631127",
          name: "玉林市实验幼儿园(滨江园)",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "金港路16号(滨江公馆内)",
          tel: "0775-2293456",
          area: "玉州区",
        },
        {
          lan: "110.171453,22.653939",
          name: "玉林市实验幼儿园名山园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "广西壮族自治区玉林市玉州区名门路",
          tel: "17776703456",
          area: "玉州区",
        },
        {
          lan: "110.171453,22.648315",
          name: "玉林市贝贝幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "玉林市陆川县温泉镇通政西路金天地",
          tel: "15777515177",
          area: "玉州区",
        },
        {
          lan: "109.985805,22.283972",
          name: "小牛津幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "广西壮族自治区玉林市博白县新仲公园",
          tel: "17776703456",
          area: "博白县",
        },
        {
          lan: "109.990381,22.269005",
          name: "南城艺术幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "玉林市博白县南城路a29栋",
          tel: "2956589",
          area: "博白县",
        },
        {
          lan: "110.542808,22.852488",
          name: "玉林市实验幼儿园(滨江园容县启英幼儿园）",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "容州镇城西大道55-1号",
          tel: "18074751155",
          area: "容县",
        },
        {
          lan: "110.355462,22.715365",
          name: "广西壮族自治区北流市幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "新芝路3号",
          tel: "(0775)6222248",
          area: "北流市",
        },
        {
          lan: "109.880988,22.74761",
          name: "天使幼儿园(解放路)",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "玉林市兴业县解放路249号",
          tel: "(0775)8815561",
          area: "兴业县",
        },
        {
          lan: "109.883225,22.743673",
          name: "兴业七团双语艺术幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "南街路115",
          tel: "(0775)6222248",
          area: "兴业县",
        },
        {
          lan: "109.887037,22.746725",
          name: "梦之苑幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "18934882509",
          area: "兴业县",
        },
        {
          lan: "110.018744,22.528705",
          name: "玉林市福绵区樟木镇劲力幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.018744,22.528705",
          name: "玉林市福绵区樟木镇中村幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "109.999845,22.579029",
          name: "玉林市福绵区成均镇第二中心幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.161432,22.63958",
          name: "玉林市环东少年业余学校",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.141279,22.64088",
          name: "玉林市玉豸中心幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市岭塘幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市南江镇石子岭中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "106.851217,32.374979",
          name: "玉林市南江镇中心幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市华勤幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市分界圩中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.157431,22.634493",
          name: "玉林市镇邮电所幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.158245,22.640298",
          name: "玉林市玉林荔园新村保育院",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.167298,22.640318",
          name: "玉林市轻轻幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.158245,22.640298",
          name: "玉林市超人幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.155077,22.641342",
          name: "玉林市蓓蕾幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.158245,22.640298",
          name: "玉林市英华中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.173128,22.613024",
          name: "玉林市苗苗幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.161978,22.622682",
          name: "玉林市苗园中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
        {
          lan: "110.018744,22.528705",
          name: "玉林市立信中英文幼儿园",
          operationSituation: "正在运营中",
          safeLevel: "高等",
          address: "建设路72号",
          tel: "2956589",
          area: "玉州区",
        },
      ],
      //各区县的经纬度
      geoCoordMap: {
        朝阳客户服务中心: [120.40877, 41.479389],
        国网朝阳市开发区供电分公司: [120.303376, 41.786749],
        国网朝阳市双塔区供电分公司: [120.52877, 41.539389],
        "双塔供电分公司(旧)": [120.52877, 41.629389],
        国网朝阳市龙城区供电分公司: [120.293376, 41.536749],
        "龙城供电分公司(旧)": [120.343376, 41.626749],
        国网北票市供电公司: [120.766951, 41.803286],
        国网朝阳县供电公司: [120.252561, 41.242745],
        国网建平县供电公司: [119.642363, 41.602576],
        建平供电分公司_农电上划: [119.642363, 41.712576],
        国网喀左县供电公司: [119.744883, 41.125428],
        国网凌源市供电公司: [119.294789, 40.9],
        "建平供电（热水）": [119.772323, 41.652576],
      },
      workorderSumData: [
        { name: "朝阳客户服务中心", value: 11 },
        { name: "国网朝阳市开发区供电分公司", value: 22 },
        { name: "国网朝阳市双塔区供电分公司", value: 33 },
        { name: "双塔供电分公司(旧)", value: 55 },
        { name: "国网朝阳市龙城区供电分公司", value: 66 },
        { name: "龙城供电分公司(旧)", value: 77 },
        { name: "国网北票市供电公司", value: 88 },
        { name: "国网朝阳县供电公司", value: 99 },
        { name: "国网建平县供电公司", value: 111 },
        { name: "建平供电分公司_农电上划", value: 222 },
        { name: "国网喀左县供电公司", value: 333 },
        { name: "国网凌源市供电公司", value: 555 },
        { name: "建平供电（热水）", value: 666 },
      ],
    };
  },
  mounted() {
    this.mapChart();
  },
  methods: {
    // 获取经纬度
    convertData(data) {
      var res = [];
      for (var i = 0; i < data.length; i++) {
        res.push({
          name: data[i].name, //获取该地区的名字
          value: data[i].lan.split(",").concat(data[i].tel), //获取该地区要展示的数据
        });
      }
      console.log(res);
      return res;
    },
    //绘制地图
    mapChart() {
      // 拿到一个实例
      let myChart = echarts.init(this.$refs.zhaoyang);
      console.log(yulin);
      console.log(zhaoyang);
      //引入地图文件
      echarts.registerMap("玉林市", yulin, {});
      //设置地图属性
      var option = {
        //鼠标移动到散点上显示的数据
        tooltip: {
          trigger: "item",
          //自定义显示信息
          formatter: function (params) {
            return params.data.name + " : " + params.data.value[2] + "单";
          },
        },
        //地图信息
        geo: {
          map: "玉林市",
          type: "map",
          //设置地图边线发光
          label: { show: true, color: "#D4EEFF" }, //显示地点
          itemStyle: {
            normal: {
              //地图背景透明
              areaColor: "rgba(23,27,57,0)",
              //地图边缘线的颜色
              borderColor: "#f9f9fb",
              //地图边缘线的粗细
              borderWidth: 2,
              //背景颜色
              shadowColor: "#034ef1",
              //透明度（有透明度才会有发光的现象）
              shadowBlur: 20,
            },
          },
          //设置地图位置
          layoutCenter: ["50%", "50%"], //设置后left/right/top/bottom等属性无效
          //设置地图大小
          layoutSize: "70%",
          //鼠标移动地图上显示
          emphasis: {
            label: {
              //功能关闭
              show: true,
              //显示字体大小
              fontSize: 15,
              //显示字体颜色
              color: "#ff0026",
            },
          },
          //地图缩放拖拽
          roam: true,
          regions: [
            //对不同的区块进行着色
            {
              selected: false,
              name: "容县",
              value1: 87,
              value2: 78419,
              value3: 6175,
              value4: 11,
              value5: 1,
              itemStyle: {
                normal: {
                  areaColor: "#73bbf9",
                },
              },
            },
            {
              selected: false,
              name: "兴业县",
              value1: 62,
              value2: 76528,
              value3: 5775,
              value4: 14,
              value5: 1,
              itemStyle: {
                normal: {
                  areaColor: "#a9d6fd",
                },
              },
            },
            {
              selected: false,
              name: "玉州区",
              value1: 434,
              value2: 107632,
              value3: 21762,
              value4: 7,
              value5: 0,
              itemStyle: {
                normal: {
                  areaColor: "#7cbaf0",
                },
              },
            },
            {
              selected: false,
              name: "北流市",
              value1: 255,
              value2: 69291,
              value3: 7025,
              value4: 5,
              value5: 0,
              itemStyle: {
                normal: {
                  areaColor: "#a2d1f9",
                },
              },
            },
            {
              name: "陆川县",
              selected: false,
              value1: 157,
              value2: 68118,
              value3: 7225,
              value4: 15,
              value5: 3,
              itemStyle: {
                normal: {
                  areaColor: "#83c1f7",
                },
              },
            },
            {
              selected: false,
              name: "博白县",
              value1: 219,
              value2: 69244,
              value3: 4553,
              value4: 9,
              value5: 2,
              itemStyle: {
                normal: {
                  areaColor: "#a9d6fd",
                },
              },
            },
            {
              selected: false,
              name: "福绵区",
              value1: 219,
              value2: 69244,
              value3: 4553,
              value4: 9,
              value5: 2,
              itemStyle: {
                normal: {
                  areaColor: "#a9d6fd",
                },
              },
            },
          ],
        },
        series: [
          {
            name: "分公司总工单",
            //设置涟漪特效属性
            type: "effectScatter",
            //引入地图
            coordinateSystem: "geo",
            //输入数据（需要获取每个地区的经纬度）
            data: this.convertData(this.data_info),
            //涟漪特效
            rippleEffect: {
              //动画时长
              period: 5,
              //波纹的最大缩放比例
              scale: 4,
              //有stroke(涟漪)和fill(扩散)，两种效果
              brushType: "stroke",
            },
            //波点大小
            symbolSize: 15,
            // symbolSize: function (val) {
            //   return val[2] / 1000;
            // },
            label: {
              //是否显示地图上标记的点的文字大小
              formatter: "{b}",
              position: "right",
              show: false,
            },
            itemStyle: {
              normal: {
                color: "#f00", //地图各地区的点和文字的颜色
                shadowBlur: 10,
                shadowColor: "#333", //地图各地区的点的阴影颜色
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.zhaoyang {
  width: 100%;
  height: 80%;
}
</style>
