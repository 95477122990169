<template>
    <div class="echarts-box" id="stackLineMore2" style="display:inline-block;width:100%;height:100%;"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
    name: "HelloWorld",
    props: {
        msg: String,
    },
    data() {
        return {

        };
    },
    created() { },
    mounted() {
        this.initEcharts();
    },
    methods: {
        initEcharts() {
            var myChart_init_1 = echarts.init(
                document.getElementById("stackLineMore2")
            );
            let option_init_1 = {
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        name: '疫苗情况',
                        type: 'pie',
                        radius: '40%',
                        data: [{
                            value: 46960,
                            name: '3针',
                            itemStyle: {
                                color: '#85C4E7'
                            }
                        },
                        {
                            value: 17944,
                            name: '2针',
                            itemStyle: {
                                color: '#6984D5'
                            }
                        },
                        {
                            value: 119,
                            name: '1针',
                            itemStyle: {
                                color: '#8782D2'
                            }
                        },
                         {
                            value: 23,
                            name: '无',
                            itemStyle: {
                                color: '#8783D2'
                            }
                        },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            myChart_init_1.setOption(option_init_1);

        },
    },

};
</script>

<style scoped>
.echarts-box {
    width: 100%;
    height: 100%;
}
</style>
