<template>
  <div class="echarts-box" id="stackLineMore4" style="display:inline-block;width:100%;height:100%;"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      sidebarList: [
        {imgsrc: "./src/imgs/page.png"},
        {imgsrc: "../imgs/serve_1.png"},
        {imgsrc: "../imgs/school.png"},
      ],
    };
  },
  created() {
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
          document.getElementById("stackLineMore4")
      );
      let option_init_1 = {
        color: ['#409eff', '#f72c5b', '#16e28d'],
        title: {
          // text: 'Male and female height and weight distribution',
          // subtext: 'Data from: Heinz 2003'
        },
        grid: {
          left: '3%',
          right: '7%',
          bottom: '7%',
          containLabel: true
        },
        tooltip: {
          // trigger: 'axis',
          showDelay: 0,
          formatter: function (params) {
            if (params.value.length > 1) {
              return (
                  params.seriesName +
                  ' :<br/>' +
                  params.value[0] +
                  ' ' +
                  params.value[1] +
                  ' '
              );
            } else {
              return (
                  params.seriesName +
                  ' :<br/>' +
                  params.name +
                  ' : ' +
                  params.value +
                  'kg '
              );
            }
          },
          // axisPointer: {
          //   show: true,
          //   type: 'cross',
          //   lineStyle: {
          //     type: 'dashed',
          //     width: 1
          //   }
          // }
        },
        // toolbox: {
        //   feature: {
        //     dataZoom: {},
        //     brush: {
        //       type: ['rect', 'polygon', 'clear']
        //     }
        //   }
        // },
        // brush: {},
        legend: {
          // data: ['Female', 'Male'],
          left: 'center',
          bottom: 10
        },
        xAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              formatter: '{value} ',
              color: '#FFFFFF',
            },
            axisTick:{
              //去掉轴的刻度
              show:false,
            },
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            axisLabel: {
              formatter: '{value}',
              color: '#FFFFFF',
            },
            axisTick:{
              //去掉轴的刻度
              show:false,
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            // name: 'Female',
            type: 'scatter',
            emphasis: {
              focus: 'series'
            },
            // prettier-ignore公立
            data: [[558, 3231], [991, 3104], [553, 2927], [717, 3243], [512, 3213],
              [1151, 3404], [992, 3880], [493, 2574], [407, 2965], [545, 3478],
              [757, 3056], [976, 3975],],
            markArea: {
              silent: true,
              itemStyle: {
                color: 'transparent',
                borderWidth: 1,
                borderType: 'dashed'
              },
              // data: [
              //   [
              //     {
              //       // name: 'Female Data Range',
              //       xAxis: 'min',
              //       yAxis: 'min'
              //     },
              //     {
              //       xAxis: 'max',
              //       yAxis: 'max'
              //     }
              //   ]
              // ]
            },
            markPoint: {
              // data: [
              //   { type: 'max', name: 'Max' },
              //   { type: 'min', name: 'Min' }
              // ]
            },
            markLine: {
              lineStyle: {
                type: 'solid'
              },
              // data: [{ type: 'average', name: 'AVG' }, { xAxis: 160 }]
            }
          },
          {
            // name: 'Male',
            type: 'scatter',
            emphasis: {
              focus: 'series'
            },
            // prettier-ignore 普惠
            data: [[1147, 3333], [627, 2988], [1194, 3844], [799, 3688], [362, 2548],
              [726, 3247], [547, 3435], [1143, 3468], [524, 2534], [1186, 4101],
              [508, 3016], [429, 3427], [983, 3677], [844, 3489], [1148, 3164],
              [1221, 3469], [1206, 3429], [644, 3369], [716, 3535], [959, 3949],
              [1170, 3451], [1260, 4032], [969, 3281], [1187, 3923], [942, 3355],
            ],
            markArea: {
              silent: true,
              itemStyle: {
                color: 'transparent',
                borderWidth: 1,
                borderType: 'dashed'
              },
              // data: [
              //   [
              //     {
              //       // name: 'Male Data Range',
              //       xAxis: 'min',
              //       yAxis: 'min'
              //     },
              //     {
              //       xAxis: 'max',
              //       yAxis: 'max'
              //     }
              //   ]
              // ]
            },
            markPoint: {
              // data: [
              //   { type: 'max', name: 'Max' },
              //   { type: 'min', name: 'Min' }
              // ]
            },
            markLine: {
              lineStyle: {
                type: 'solid'
              },
              // data: [{ type: 'average', name: 'Average' }, { xAxis: 170 }]
            }
          },
          // 第三个 私立

          {
            // name: 'Malex',
            type: 'scatter',
            emphasis: {
              focus: 'series'
            },
            // prettier-ignore 普惠
            data: [
              [1005, 3630], [383, 3107], [660, 3580], [786, 3683], [1041, 3096],
              [1019, 3498], [1178, 4138], [976, 3682], [1072, 3519], [747, 3339],
              [428, 2519], [856, 2964], [831, 3594]
            ],
            markArea: {
              silent: true,
              itemStyle: {
                color: 'transparent',
                borderWidth: 1,
                borderType: 'dashed'
              },
              // data: [
              //   [
              //     {
              //       // name: 'Malex Data Range',
              //       xAxis: 'min',
              //       yAxis: 'min'
              //     },
              //     {
              //       xAxis: 'max',
              //       yAxis: 'max'
              //     }
              //   ]
              // ]
            },
            markPoint: {
              // data: [
              //   { type: 'max', name: 'Max' },
              //   { type: 'min', name: 'Min' }
              // ]
            },
            markLine: {
              lineStyle: {
                type: 'solid'
              },
              // data: [{ type: 'average', name: 'Average' }, { xAxis: 170 }]
            }
          },

        ]
      };
      myChart_init_1.setOption(option_init_1);

    },
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.echarts-box {
  width: 100%;
  height: 100%;
}
</style>
