<template>
  <div
    class="echarts-box"
    id="stackLineMore"
    style="display: inline-block; width: 100%; height: 100%"
  ></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
        document.getElementById("stackLineMore")
      );
      let option_init_1 = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        color: ["#85C4E7", "#6984D5", "#8782D2", "#A498D6", "#5DA6D3"],
        legend: {
          data: ["日活跃", "周活跃", "双周活跃", "月活跃", "双月活跃"],
          textStyle: {
            color: "rgb(255, 255, 255) ",
          },
          lineStyle: {
            normal: {},
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {},
        },
        //X轴
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
          },
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周天"],

          splitLine: {
            show: false,
            lineStyle: {
              // 边框颜色
              // color:'#00ffee',
              width: 2,
            },
          },
        },
        //Y轴
        yAxis: {
          // min:0.3,
          // max:0.6,
          // scale:true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
          },
          type: "value",
          splitLine: {
            show: false,
          },
        },
        //折线
        series: [
          {
            name: "日活跃",
            type: "line",
            stack: "总量",
            smooth: true,
            data: [267, 273, 272, 269, 266, 265, 267],
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
          {
            name: "周活跃",
            type: "line",
            stack: "总量",
            smooth: true,
            data: [303, 291, 294, 290, 299, 302, 300],
            lineStyle: {
              normal: {
                color: "#6984D5",
              },
            },
          },
          {
            name: "双周活跃",
            type: "line",
            stack: "总量",
            smooth: true,
            data: [314, 305, 308, 300, 301, 316, 315],
            lineStyle: {
              normal: {
                color: "#8782D2",
              },
            },
          },
          {
            name: "月活跃",
            type: "line",
            stack: "总量",
            smooth: true,
            data: [341, 340, 342, 340, 342, 345, 343],
            lineStyle: {
              normal: {
                color: "#A498D6",
              },
            },
          },
          {
            name: "双月活跃",
            type: "line",
            stack: "总量",
            smooth: true,
            data: [408, 401, 402, 401, 401, 405, 407],
            lineStyle: {
              normal: {
                color: "#5DA6D3",
              },
            },
          },
        ],
      };
      myChart_init_1.setOption(option_init_1);
    },
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.echarts-box {
  width: 100%;
  height: 100%;
}
</style>
