import Router from "vue-router";
import PlatformUsageCenter01 from "../components/PlatformUsageCenter01.vue";
import PreventionControCenter02 from "../components/PreventionControCenter02.vue";
import intelligentBobot03 from "../components/intelligentBobot03.vue";
import security from "../components/security.vue";
import teacher from "../components/teacher.vue";
import fire from "../components/fire.vue";
import register from "../components/register.vue";
import manage from "../components/manage.vue";
///import map from "../components/map.vue";

export default new Router({
    routes: [
        // {
        //     path: "/", //到时候地址栏会显示的路径
        //     name: "map",
        //     component: map, // Home是组件的名字，这个路由对应跳转到的组件。。注意component没有加“s”.
        // },
        {
            path: "/", //到时候地址栏会显示的路径
            name: "PlatformUsageCenter01",
            component: PlatformUsageCenter01, // Home是组件的名字，这个路由对应跳转到的组件。。注意component没有加“s”.
        },
        {
            path: "/PreventionControCenter02",
            name: "PreventionControCenter02",
            component: PreventionControCenter02,
        },
        {
            path: "/intelligentBobot03",
            name: "intelligentBobot03",
            component: intelligentBobot03,
        },
        {
            path: "/security",
            name: "security",
            component: security,
        },
        {
            path: "/teacher",
            name: "teacher",
            component: teacher,
        },
        {
            path: "/fire",
            name: "fire",
            component: fire,
        },
        {
            path: "/register",
            name: "register",
            component: register,
        },
    ],
    mode: "history",
});

