<template>
  <div class="flex_L_1">
    <i class="topL"></i>
    <i class="topR"></i>
    <i class="bottomL"></i>
    <i class="bottomR"></i>
    <div class="type main-title" style="padding-left: 10px">幼儿信息报表</div>
    <div class="content">
      <div class="top flex flex1">
        <echarts01 class="flex1" />
        <echarts02 class="flex1" />
        <echarts03 class="flex1" />
      </div>
      <div class="bottom flex flex1" style="margin: 0px 16% 0px 16%">
        <echarts04 class="flex1" />
        <echarts05 class="flex1" />
      </div>
    </div>
  </div>
</template>
<script>
import echarts01 from "./components/child-echarts01.vue";
import echarts02 from "./components/child-echarts02.vue";
import echarts03 from "./components/child-echarts03.vue";
import echarts04 from "./components/child-echarts04.vue";
import echarts05 from "./components/child-echarts05.vue";
export default {
  components: {
    echarts01,
    echarts02,
    echarts03,
    echarts04,
    echarts05,
  },
};
</script>
<style scoped>
.content {
  height: 89%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>