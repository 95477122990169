<template>
  <div class="hello">
    <div class="scatter">
      <div class="scatter_1 flex flex-column">
        <div class="flex hp3 pb10 bsizing">
          <div class="kaoqin">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div
              style="padding-left: 10px; color: aliceblue"
              class="main-title"
            >
              晨检情况
            </div>
            <echarts06 class="flex1" />
          </div>
        </div>
        <div class="flex hp3 pb10 bsizing">
          <div class="anquandongtai">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div
              style="padding-left: 10px; color: aliceblue"
              class="main-title"
            >
              请假人数
            </div>
            <echarts01 />
          </div>
        </div>
        <div class="flex hp3">
          <div class="shishidongtai">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div
              style="padding-left: 10px; color: aliceblue"
              class="main-title"
            >
              开园情况
            </div>
            <echarts05 class="flex1" />
          </div>
        </div>
      </div>
      <div id="scatter_2">
        <i class="topL"></i>
        <i class="topR"></i>
        <i class="bottomL"></i>
        <i class="bottomR"></i>
        <div id="box">
          <div id="box_1">
            晨检人员
            <input type="text" value="12277" id="input_1" />
          </div>
          <div id="box_1">
            请假人数
            <input type="text" value="756" id="input_2" />
          </div>
          <div id="box_3">
            外访人员
            <input type="text" value="127" id="input_3" />
          </div>
          <div id="box_2">
            疫苗情况
            <input type="text" value="12277" id="input_4" />
          </div>
          <div id="box_3">
            异常事件
            <input type="text" value="10" id="input_5" />
          </div>
        </div>
        <echarts07 class="flex1" />
      </div>
      <div class="scatter_1 flex flex-column space-between">
        <div class="flex hp3 pb10 bsizing">
          <div class="zonghe">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div
              style="padding-left: 10px; color: aliceblue"
              class="main-title"
            >
              核酸及疫苗
            </div>
            <div id="record">
              <div id="record_1">
                <echarts02 />
              </div>
              <div id="record_2">
                <echarts03 />
              </div>
            </div>
          </div>
        </div>
        <div class="flex hp3 pb10 bsizing">
          <div class="flex1 anquan">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div
              style="padding-left: 10px; color: aliceblue"
              class="main-title"
            >
              外访人员
            </div>
            <echarts04 />
          </div>
        </div>
        <div class="hp3">
          <div class="anquan1" style="height: 100%; max-height: 100%">
            <i class="topL"></i>
            <i class="topR"></i>
            <i class="bottomL"></i>
            <i class="bottomR"></i>
            <div class="main-title title1" style="padding-left: 10px">
              异常情况
            </div>
            <list class="scrol-box" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts01 from "./PreventionControCenter02/echars01.vue";
import echarts02 from "./PreventionControCenter02/echars02.vue";
import echarts03 from "./PreventionControCenter02/echars03.vue";
import echarts04 from "./PreventionControCenter02/echars04.vue";
import echarts05 from "./PreventionControCenter02/echars05.vue";
import echarts06 from "./PreventionControCenter02/echars06.vue";
import echarts07 from "./PreventionControCenter02/echars07.vue";
import list from "./PreventionControCenter02/list.vue";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      top: "",
      scrollIndex: 0,
      p: "",
      lastData: "",
      nowData: "",
      nextData: "",
    };
  },
  mounted() {},

  methods: {},

  components: {
    echarts01,
    echarts02,
    echarts03,
    echarts04,
    echarts05,
    echarts06,
    echarts07,
    list,
  },
};
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.Sixframe {
  height: 30px;
  color: brown;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: space-around;
}

.ThreeFrames {
  display: flex;
  flex: 1;
}

.frames1 {
  width: 60px;
}

.Faces li {
  color: #1296db;
  font-weight: bold;
  font-size: 18px;
}

.zonghee {
  height: 13.33%;
  width: 100%;
  border: 2px solid #042b66;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}

.mainbox {
  width: 500px;
  height: 200px;
  border: 1px solid saddlebrown;
}

.anquan {
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}

.anquan1 {
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.posi-rela {
  position: relative;
}

.container-column-two {
  position: relative;
  height: 200px;
}

.container-column-three {
  position: relative;
  flex: 1;
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.zonghe {
  width: 100%;
  border: 2px solid #042b66;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: inset 0 0 30px #07417a;
  position: relative;
}

.pb10 {
  padding-bottom: 10px;
}
#current {
  box-sizing: border-box;
  height: 80%;
  width: 100%;
  border-top: 2px solid #042b66;
}
.pb5 {
  padding-bottom: 5px;
}

.scrol-box {
  height: calc(100% - 40px);
  overflow: hidden;
  box-sizing: border-box;
}

.title1 {
  color: #fff;
}

.box {
  padding-top: 5px;
}
</style>
