<template>
    <div class="echarts-box" id="stackLineMore1" style="display:inline-block;width:100%;height:100%;"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
    name: "HelloWorld",
    props: {
        msg: String,
    },
    data() {
        return {

        };
    },
    created() { },
    mounted() {
        this.initEcharts();
    },
    methods: {
        initEcharts() {
            var myChart_init_1 = echarts.init(
                document.getElementById("stackLineMore1")
            );
            let option_init_1 = {
                tooltip: {
                    trigger: 'item'
                },

                series: [
                    {
                        name: '核酸时间',
                        type: 'pie',
                        radius: '40%',
                        data: [{
                            value: 13658,
                            name: '24小时',
                            itemStyle: {
                                color: '#85C4E7'
                            }
                        },
                        {
                            value: 35762,
                            name: '48小时',
                            itemStyle: {
                                color: '#6984D5'
                            }
                        },
                        {
                            value: 25789,
                            name: '72小时',
                            itemStyle: {
                                color: '#8782D2'
                            }
                        },
                           {
                            value: 21,
                            name: '其他',
                            itemStyle: {
                                color: '#8782c2'
                            }
                        },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            myChart_init_1.setOption(option_init_1);

        },
    },

};
</script>

<style scoped>
.echarts-box {
    width: 100%;
    height: 100%;
}
</style>
