<template>
  <div
    class="echarts-box"
    id="info_1"
    style="display: inline-block; width: 100%; height: 100%"
  ></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart = echarts.init(document.getElementById("info_1"));

      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          x: "left",
          data: ["3岁以下", "3-4岁", "4-5岁", "5-6岁"],
          textStyle: {
            color: " rgb(255, 255, 255) ",
          },
          normal: {
            show: true,
            position: "inner",
            formatter: "{a}",
            fontSize: 17,
          },
        },

        toolbox: {
          show: true,
          feature: {
            mark: {
              show: false,
            },
            dataView: {
              show: false,
              readOnly: false,
            },
            magicType: {
              show: false,
              type: ["pie", "funnel"],
              option: {
                funnel: {
                  x: "25%",
                  width: "50%",
                  funnelAlign: "center",
                  max: 1548,
                },
              },
            },
          },
        },
        calculable: true,
        title: [
          {
            text: "宝宝年纪",
            x: "54%",
            y: "56%",
            textStyle: {
              color: " rgb(255, 255, 255) ",
              fontSize: 14,
            },
          },
        ],

        series: [
          {
            name: "宝宝年纪",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["65%", "60%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
              emphasis: {
                label: {
                  show: false,
                  position: "center",
                },
              },
            },
            data: [
              {
                value: 10,
                name: "3岁以下",
                itemStyle: {
                  color: "#85C4E7",
                },
              },
              {
                value: 31,
                name: "3-4岁",
                itemStyle: {
                  color: "#6984D5",
                },
              },
              {
                value: 29,
                name: "4-5岁",
                itemStyle: {
                  color: "#8782D2",
                },
              },
              {
                value: 30,
                name: "5-6岁",
                itemStyle: {
                  color: "#A498D6",
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.echarts-box {
  width: 100%;
  height: 100%;
}
</style>
