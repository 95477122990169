<template>
  <div class="echarts-box" id="stackLineMore1" style="display:inline-block;width:100%;height:100%;"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      sidebarList: [
        { imgsrc: "./src/imgs/page.png" },
        { imgsrc: "../imgs/serve_1.png" },
        { imgsrc: "../imgs/school.png" },
      ],
      data2: [12, 11, 12, 12, 13, 10, 10],
      data3: [28, 26, 25, 24, 23, 20, 20],
      data4: [43, 41, 40, 39, 38, 35, 35],
      data5: [73, 71, 70, 69, 68, 65, 65],
      data6: [128, 126, 125, 124, 124, 121, 121],
    };
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
        document.getElementById("stackLineMore1")
      );
      let option_init_1 = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        color: ["#85C4E7", "#6984D5", "#8782D2", "#A498D6", "#5DA6D3"],
        legend: {
          // data: [
          //   "多折线图标题一",
          //   "多折线图标题二",
          //   "多折线图标题三",
          //   "多折线图标题四",
          //   "多折线图标题五",
          // ],
          textStyle: {
            color: "rgb(255, 255, 255) ",
          },
          lineStyle: {
            normal: {},
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {},
        },
        //X轴
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick:{
              show:false,
          },
          axisLine: {
            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
          },
           data: ["周二","周三","周四","周五","周六","周日","周一"],
          splitLine: {
            show: false,
            lineStyle: {
              // 边框颜色
              // color:'#00ffee',
              width: 2,
            },
          },
        },
        //Y轴
        yAxis: {
          // min:0.3,
          // max:0.6,
          // scale:true,
          axisLine: {
            show:true,
            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
          },
          type: "value",
          splitLine: {
            show: false,
          },
        },
        //折线
        series: [
          {
            name: "日注册用户",
            type: "line",
            stack: "总量",
            smooth: true,
            data: this.data2,
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
          {
            name: "周注册用户",
            type: "line",
            stack: "总量",
            smooth: true,
            data: this.data3,
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
          {
            name: "双周注册用户",
            type: "line",
            stack: "总量",
            smooth: true,
            data: this.data4,
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
          {
            name: "月注册用户",
            type: "line",
            stack: "总量",
            smooth: true,
            data: this.data5,
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          }
        ],
      };
      myChart_init_1.setOption(option_init_1);
    },
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .echarts-box{
        width:100%;
        height: 100%;
    }
</style>
