<template>
  <div id="app">
    <Header />
    <sidebar />
    <div class="scatter">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import sidebar from "./components/sidebar.vue";
import Header from "./components/header.vue";
//import PlatformUsageCenter01 from './components/PlatformUsageCenter01.vue'
export default {
  name: "App",
  components: {
    sidebar,
    Header,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.main-title {
  border-bottom: 2px solid #042b66;
  height: 30px;
  line-height: 30px;
  color: #1296DB!important;
}
</style>
