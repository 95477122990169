<template>
  <div
    class="echarts-box"
    id="info_6"
    style="display: inline-block; width: 100%; height: 100%"
  ></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      // info_6
      var myChart = echarts.init(document.getElementById("info_6"));
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["正常", "病假", "事假"],
          textStyle: {
            color: " rgb(255, 255, 255) ",
          },
        },
        calculable: true,
        xAxis: [
          {
            axisLabel: {
              rotate: 30,
              interval: 0,
            },
            axisLine: {
              lineStyle: {
                color: "#CECECE",
              },
            },
            type: "category",
            boundaryGap: false,
            data: [
              "2022/3/1",
              "2022/3/2",
              "2022/3/3",
              "2022/3/4",
              "2022/3/5",
              "2022/3/6",
              "2022/3/7",
              "2022/3/8",
              "2022/3/9",
              "2022/3/10",
              "2022/3/11",
              "2022/3/12",
              "2022/3/13",
              "2022/3/14",
              "2022/3/15",
            ],
          },
        ],
        yAxis: [
          {
            show: false,
          },
        ],
        series: [
          {
            name: "正常",
            type: "line",
            symbol: "none",
            smooth: 0.2,
            color: ["#1296DB"],
            data: [
              4800, 4300, 4500, 4800, 4300, 4800, 4300, 4500, 4800, 4300, 4800,
              4300, 4500, 4800, 4300,
            ],
          },
          {
            name: "病假",
            type: "line",
            symbol: "none",
            smooth: 0.2,
            color: ["#6984D5"],
            data: [
              1400, 1400, 1400, 1400, 1400, 2400, 1400, 1400, 1400, 1400, 1400,
              1400, 1400, 1400, 1400,
            ],
          },
          {
            name: "事假",
            type: "line",
            symbol: "none",
            smooth: 0.2,
            color: ["#8782D2"],
            data: [
              200, 300, 200, 200, 400, 200, 2100, 200, 200, 300, 200, 200, 200,
              200, 200,
            ],
          },
        ],
      };

      myChart.setOption(option);
    },
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.echarts-box {
  width: 100%;
  height: 100%;
}
</style>
