<template>
  <div id="parent" class="parent">
    <ul id="child" class="child">
      <li v-for="(item, index) in list1" :key="index">
        <span>{{ item.name }}</span
        ><span>{{ item.time }}</span>
      </li>
    </ul>
    <div id="cloneChild" class="child"></div>
  </div>
</template>
<script>
import { formatData } from "../../utils/index.js";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      list1:[],
      list: [
        { name: "玉林市实验幼儿园小班打开了第3单元课件" },
        { name: "玉林市名山园中班关闭了第5单元课件" },
        { name: "玉林市蓓蕾幼儿园大班打开了第5单元课件" },
        { name: "玉林市超人幼儿园小班关闭了第3单元课件" },
        { name: "苗园中英文幼儿园大班打开了第4单元课件" },
        { name: "玉林市苗苗幼儿园中班打开了第5单元课件" },
        { name: "英华中英文幼儿园小班关闭了第4单元课件" },
        { name: "玉林市实验幼儿园小班打开了第3单元课件" },
        { name: "玉林市名山园中班关闭了第5单元课件" },
        { name: "玉林市蓓蕾幼儿园大班打开了第5单元课件" },
        { name: "玉林市超人幼儿园小班关闭了第3单元课件" },
        { name: "苗园中英文幼儿园大班打开了第4单元课件" },
        { name: "玉林市苗苗幼儿园中班打开了第5单元课件" },
        { name: "英华中英文幼儿园小班关闭了第4单元课件" },
        { name: "玉林市实验幼儿园小班打开了第3单元课件" },
        { name: "玉林市名山园中班关闭了第5单元课件" },
        { name: "玉林市蓓蕾幼儿园大班打开了第5单元课件" },
        { name: "玉林市超人幼儿园小班关闭了第3单元课件" },
        { name: "苗园中英文幼儿园大班打开了第4单元课件" },
        { name: "玉林市苗苗幼儿园中班打开了第5单元课件" },
        { name: "英华中英文幼儿园小班关闭了第4单元课件" },
        { name: "玉林市实验幼儿园小班打开了第3单元课件" },
        { name: "玉林市名山园中班关闭了第5单元课件" },
        { name: "玉林市蓓蕾幼儿园大班打开了第5单元课件" },
        { name: "玉林市超人幼儿园小班关闭了第3单元课件" },
        { name: "苗园中英文幼儿园大班打开了第4单元课件" },
        { name: "玉林市苗苗幼儿园中班打开了第5单元课件" },
        { name: "英华中英文幼儿园小班关闭了第4单元课件" },
        { name: "玉林市实验幼儿园小班打开了第3单元课件" },
        { name: "玉林市名山园中班关闭了第5单元课件" },
        { name: "玉林市蓓蕾幼儿园大班打开了第5单元课件" },
        { name: "玉林市超人幼儿园小班关闭了第3单元课件" },
        { name: "苗园中英文幼儿园大班打开了第4单元课件" },
        { name: "玉林市苗苗幼儿园中班打开了第5单元课件" },
        { name: "英华中英文幼儿园小班关闭了第4单元课件" },
        { name: "玉林市实验幼儿园小班打开了第3单元课件" },
        { name: "玉林市名山园中班关闭了第5单元课件" },
        { name: "玉林市蓓蕾幼儿园大班打开了第5单元课件" },
        { name: "玉林市超人幼儿园小班关闭了第3单元课件" },
        { name: "苗园中英文幼儿园大班打开了第4单元课件" },
        { name: "玉林市苗苗幼儿园中班打开了第5单元课件" },
        { name: "英华中英文幼儿园小班关闭了第4单元课件" }
      ],
    };
  },
  created() {},
  mounted() {
    console.log()
    this.list.forEach((val) => {
      val.time = formatData()
    });
    this.list1 = this.list
    //this.$updatesource()
    console.log(this.list)
    this.initEcharts();
  },
  methods: {
    formatData(value) {
        var date = new Date(parseInt(value + '000'));
        //date.setTime(value);
        var month = date.getMonth() + 1;
        var hours = date.getHours();
        if (hours < 10)
            hours = "0" + hours;
        var minutes = date.getMinutes();
        if (minutes < 10)
            minutes = "0" + minutes;
        var time = date.getFullYear() + "-" + month + "-" + date.getDate() +
            " " + hours + ":" + minutes;
        return time;
    },
    initEcharts() {
      let parent = document.getElementById("parent");
      let child = document.getElementById("child");
      // child.innerHTML = str;
      let cloneChild = document.getElementById("cloneChild");
      // 深度克隆一份表格 相比 innerHTML 的优势在于可以克隆元素的全部的属性
      let cloneNoe = child.cloneNode(true);
      // 追加到 parent 里面 做无缝切换视觉效果
      parent.appendChild(cloneNoe);
      this.myScroll = setInterval(function () {
        // parent.scrollTop + parent.clientHeight = child.scrollHeight;
        // child.scrollHeight - parent.scrollTop = parent.clientHeight;
        // 让他多滚动 parent 一显示区域的高度。再跳到 最顶部 ，正好 给人一种在不断滚动的错觉
        if (parent.scrollTop >= child.scrollHeight) {
          parent.scrollTop = 0;
        } else {
          parent.scrollTop++;
        }
      }, 20);

      // //以下为鼠标悬停的事件
      parent.onmouseover = () => {
        clearInterval(this.myScroll);
      };
      parent.onmouseout = () => {
        this.myScroll = setInterval(function () {
          if (parent.scrollTop >= child.scrollHeight) {
            parent.scrollTop = 0;
          } else {
            parent.scrollTop++;
          }
        }, 20);
      };
    },
    scrollUp() {},
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.parent {
  height: calc(100% - 40px);
  overflow: hidden;
  background-color: transparent;
}
ul li {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
