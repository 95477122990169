<template>
  <div class="hello">
    <div id="skip">
      <!-- <div class="use" id="page" v-for="(item,index) in sidebarList" :key="index">
            <div class="tu_1"><img :src="item.imgsrc" alt=""></div>
        </div> -->
      <div class="use" id="page" @click="routerGo(1)">
        <div class="tu_1">
          <img src="../imgs/page.png" alt="6666" title="首页" />
        </div>
      </div>
      <div class="use" id="analysis" @click="routerGo(2)">
        <div class="tu_1">
          <img src="../imgs/serve_1.png" alt="" title="服务" />
        </div>
      </div>
      <div class="use" id="manage" @click="routerGo(3)">
        <div class="tu_1">
          <img src="../imgs/school.png" alt="" title="学校" />
        </div>
      </div>
      <div class="use" id="serve" @click="routerGo(5)">
        <div class="tu_1">
          <img src="../imgs/icon1.png" alt="" title="安防" />
        </div>
      </div>
      <div class="use" id="serve" @click="routerGo(6)">
        <div class="tu_1">
          <img src="../imgs/icon2.png" alt="" title="师资力量" />
        </div>
      </div>
      <div class="use" id="serve" @click="routerGo(7)">
        <div class="tu_1">
          <img src="../imgs/icon3.png" alt="" title="消防" />
        </div>
      </div>
      <div class="use" id="serve" @click="routerGo(8)">
        <div class="tu_1">
          <img src="../imgs/icon5.png" alt="" title="注册" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// routes: [
//         {
//             path: "/", //到时候地址栏会显示的路径
//             name: "PlatformUsageCenter01",
//             component: PlatformUsageCenter01, // Home是组件的名字，这个路由对应跳转到的组件。。注意component没有加“s”.
//         },
//         {
//             path: "/PreventionControCenter02",
//             name: "PreventionControCenter02",
//             component: PreventionControCenter02,
//         },
//         {
//             path: "/intelligentBobot03",
//             name: "intelligentBobot03",
//             component: intelligentBobot03,
//         },
//     ],
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      sidebarList: [
        { imgsrc: "./src/imgs/page.png" },
        { imgsrc: "../imgs/serve_1.png" },
        { imgsrc: "../imgs/school.png" },
      ],
    };
  },
  methods: {
    routerGo(num) {
      if (num === 1) {
        this.$router.push({ path: "/" });
      } else if (num === 2) {
        this.$router.push({ path: "/PreventionControCenter02" });
      } else if (num === 3) {
        this.$router.push({ path: "/intelligentBobot03" });
      } else if (num === 5) {
        this.$router.push({ path: "/security" });
      } else if (num === 6) {
        this.$router.push({ path: "/teacher" });
      } else if (num === 7) {
        this.$router.push({ path: "/fire" });
      } else if (num === 8) {
        this.$router.push({ path: "/register" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
