<template>
  <div
    class="echarts-box"
    id="stackLineMore2"
    style="display: inline-block; width: 100%; height: 100%"
  ></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      sidebarList: [
        { imgsrc: "./src/imgs/page.png" },
        { imgsrc: "../imgs/serve_1.png" },
        { imgsrc: "../imgs/school.png" },
      ],
    };
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
        document.getElementById("stackLineMore2")
      );
      let option_init_1 = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        color: ["#85C4E7", "#6984D5", "#8782D2", "#A498D6", "#5DA6D3"],
        legend: {
          // data: [
          //   "多折线图标题一",
          //   "多折线图标题二",
          //   "多折线图标题三",
          //   "多折线图标题四",
          //   "多折线图标题五",
          // ],
          textStyle: {
            color: "rgb(255, 255, 255) ",
          },
          lineStyle: {
            normal: {},
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {},
        },
        //X轴
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
          },
          data: [
            "星期一",
            "星期天",
            "星期六",
            "星期五",
            "星期四",
            "星期三",
            "星期二",
          ],
          splitLine: {
            show: false,
            lineStyle: {
              // 边框颜色
              // color:'#00ffee',
              width: 2,
            },
          },
        },
        //Y轴
        yAxis: {
          // min:0.3,
          // max:0.6,
          // scale:true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgb(255, 255, 255) ",
            },
          },
          type: "value",
          splitLine: {
            show: false,
          },
        },
        //折线
        series: [
          {
            name: "日活跃",
            type: "line",
            stack: "总量",
            smooth: true,
            data: [267, 261, 265, 266, 269, 272, 273],
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
          {
            name: "周活跃",
            type: "line",
            stack: "总量",
            smooth: true,
            data: [403, 400, 402, 499, 490, 494, 491],
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
          {
            name: "双周活跃",
            type: "line",
            stack: "总量",
            smooth: true,
            data: [514, 515, 516, 512, 500, 508, 505],
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
          {
            name: "月活跃",
            type: "line",
            stack: "总量",
            smooth: true,
            data: [741, 743, 745, 742, 740, 742, 740],
            lineStyle: {
              normal: {
                color: "#85C4E7",
              },
            },
          },
        ],
      };
      myChart_init_1.setOption(option_init_1);
    },
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.echarts-box {
  width: 100%;
  height: 100%;
  padding-right: 10px;
}
</style>
