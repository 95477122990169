import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import './style.css'
Vue.use(VueRouter)
//1`创建组件
//  import PlatformUsageCenter01 from './components/PlatformUsageCenter01.vue'
//  import PreventionControCenter02 from './components/PreventionControCenter02.vue'
//  import intelligentBobot03 from './components/intelligentBobot03.vue'
// //2`配置路由
// const routes = [
//   {path: '/PlatformUsageCenter01',component:PlatformUsageCenter01},
//    {path: '/PreventionControCenter02',component:PreventionControCenter02},
//    {path: '/intelligentBobot03',component:intelligentBobot03}
//  ]
//  //3`实例化vuerouter

//  const router = new VueRouter({
//    routes
//  })
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
