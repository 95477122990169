<template>
  <div class="echarts-box" id="stackLineMore3"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      sidebarList: [
        { imgsrc: "./src/imgs/page.png" },
        { imgsrc: "../imgs/serve_1.png" },
        { imgsrc: "../imgs/school.png" },
      ],
    };
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    initEcharts() {
      var myChart_init_1 = echarts.init(
        document.getElementById("stackLineMore3")
      );
      let option_init_1 = {
        title: {
          show: false,
        },
        legend: {
          orient: "vertical",
          top: "bottom",
          show: false,
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: false },
            dataView: { show: false, readOnly: false },
            restore: { show: false },
            saveAsImage: { show: false },
          },
        },
        series: [
          {
            // name: 'Nightingale Chart',
            type: "pie",
            // radius: [50, 80],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              show: true,
              color: "#1296DB",
            },
            data: [
              { value: 75057, value: 3254, name: "中文故事" },
              { value: 2566, value: 588, name: "人脸识别" },
              { value: 135621, value: 872, name: "宝宝签到" },
              { value: 1284, value: 500, name: "应用设置" },
              { value: 32871, value: 1463, name: "智能提醒" },
              { value: 18763, value: 487, name: "汉语动画" },
              { value: 20, value: 20, name: "留言提醒" },
              { value: 512, value: 48, name: "系统设置" },
              { value: 8435, value: 643, name: "谜语" },
              { value: 48721, value: 1876, name: "轻音乐" },
            ],
          },
        ],
      };
      myChart_init_1.setOption(option_init_1);
    },
  },
  watch: {
    //   $router:{
    //       handler(newrouter,oldrouter){
    //           console.log(newrouter,oldrouter)
    //       },
    //       deep:true
    //   }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.echarts-box {
  height: 200px;
}
</style>
